import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SpecialButton from '../../../../../common/Form/Button'
import { ContentBox, ContentSection, ContentSummary } from '../../Elements'
import {
  Label,
  Input,
  Step,
  Container as FormPartial,
  Incentive
} from '@pods-finance/components'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs } from '../../../../../../constants'

import {
  useOptionInfo,
  useOwnBalance,
  useFormValidator,
  useFormAssetAllowance,
  useTransactionSetup,
  useNetworkId
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 226px;
    }
  }
`

const Info = styled.p``

function Mint ({ warning, isDisabled }) {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.mint.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const {
    option,
    collateral,
    underlying,
    strike,
    helper,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const { elements, state, dispatch } = reducers.mint.useReducer()

  const {
    value: balanceCollateral,
    isLoading: isBalanceCollateralLoading
  } = useOwnBalance(collateral)

  const isLoading = useMemo(
    () => isOptionLoading || isBalanceCollateralLoading,
    [isOptionLoading, isBalanceCollateralLoading]
  )

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const doAllowanceUpdate = useCallback(
    ({ isAllowed, isLoading }) =>
      dispatch([elements.allowance, { collateral: isAllowed, isLoading }]),
    [elements, dispatch]
  )

  const onPrimaryRefocus = useCallback(
    isCollateralPrimary =>
      logic.onPrimaryRefocus({
        isCollateralPrimary,
        dispatch,
        elements,
        state
      }),
    [dispatch, elements, state]
  )

  const onChangeAmount = useCallback(
    (options = null, collateral = null) =>
      logic.onChangeAmount({
        amounts: {
          options,
          collateral
        },
        dispatch,
        elements,
        option,
        balanceCollateral
      }),
    [dispatch, elements, option, balanceCollateral]
  )

  const {
    doApprove: doAllowanceApprove,
    doRefresh: doAllowanceRefresh
  } = useFormAssetAllowance({
    amount: _.get(state, 'collateral.value'),
    token: collateral,
    spender: _.get(helper, 'address'),
    onUpdate: doAllowanceUpdate
  })

  const onTransact = useCallback(() => {
    if (!isDisabled) logic.onTransact({ machine, state, option, setup })
  }, [machine, isDisabled, state, option, setup])

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        collateral,
        underlying,
        strike,
        balanceCollateral
      })
      doAllowanceRefresh()
    }
  }, [
    elements,
    dispatch,
    isLoading,
    collateral,
    underlying,
    strike,
    balanceCollateral,
    doAllowanceRefresh
  ])

  return (
    <ContentBox hash={[tabs.option.mint]} isLoading={isLoading}>
      <Wrapper>
        {warning && (
          <Incentive symbol='⚠️'>
            <p>{warning}</p>
          </Incentive>
        )}
        <ContentSection
          title='Mint option tokens to lock collateral'
          isContained
          isDisabled={
            [machine.states.validate, machine.states.process].includes(
              machine.current.value
            ) || isDisabled
          }
        >
          <Form>
            <Step>
              <Label>Step 1: Collateral to lock</Label>
              <Input.Amount
                {...state.collateral}
                placeholder='Enter amount'
                networkId={networkId}
                onChange={e => onChangeAmount(null, _.get(e, 'target.value'))}
                onClick={() => onPrimaryRefocus(true)}
              />

              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>Will mint</Info>
                <Input.Amount
                  {...state.options}
                  placeholder='Enter amount'
                  networkId={networkId}
                  onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                  onClick={() => onPrimaryRefocus(false)}
                />
              </Step>
            </Step>
            <ContentSummary
              index='2'
              context={tabs.option.mint}
              allow={
                <SpecialButton.AllowToken
                  amount={_.get(state, 'collateral.value')}
                  title={`Allow ${_.get(collateral, 'symbol')}`}
                  isAllowed={_.get(state, 'allowance.collateral')}
                  isLoading={_.get(state, 'allowance.isLoading')}
                  onApprove={doAllowanceApprove}
                />
              }
              transact={
                <SpecialButton.Transact
                  title='Mint Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

Mint.propTypes = {
  isDisabled: PropTypes.bool,
  warning: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

Mint.defaultProps = {
  isDisabled: false,
  warning: null
}

export default Mint
