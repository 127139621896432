import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Emoji } from '@pods-finance/components'
import ContentBox from '../ContentBox'
import ContentSection from '../ContentSection'
import { networks } from '@pods-finance/globals'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Info = styled.p`
  font-size: ${props => props.theme.sizes.text};
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
  font-weight: 500;

  span {
    font-size: 16pt;
  }
`

function SupportNetwork ({ networkId = networks.matic }) {
  return (
    <ContentBox force isLoading={false}>
      <Wrapper>
        <ContentSection title='You are connected to a different network.'>
          <Info>
            It looks like you're not connected to the right network for this
            option <Emoji symbol='🧐' label='Hmm' />. Please point your wallet
            to {_.get(networks, `_data[${networkId}].name`)}.
          </Info>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

export default SupportNetwork
