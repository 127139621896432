import _ from 'lodash'
import { utils } from '../../../../constants'

export function initialize ({ networkId, elements, dispatch }) {
  try {
    const factory = _.attempt(
      () => utils[networkId].find(item => item.id === 'factory').content
    )
    if (_.isError(factory)) return

    const [expiration, milliseconds] = (() => {
      const base = new Date()
      base.setDate(new Date().getDate() + 2)
      base.setMilliseconds(0)
      base.setSeconds(0)
      const local = new Date(base.getTime() - base.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, -1)
      return [local, base.getTime()]
    })()

    dispatch([elements.factory, { value: factory }])

    dispatch([
      elements.expiration,
      {
        value: expiration,
        milliseconds
      }
    ])
  } catch (e) {}
}

export function onChangeSimpleInput ({ key, value, dispatch }) {
  dispatch([
    key,
    {
      value
    }
  ])
}

export function onChangeExpirationInput ({ value, elements, dispatch }) {
  dispatch([
    elements.expiration,
    {
      value,
      milliseconds: value ? Date.parse(value) : null
    }
  ])
}

export function onTransact ({
  machine,
  state,
  signer,
  setup,
  networkId,
  elements,
  dispatch
}) {
  console.debug('Pods craft state', { state })
  machine.send(machine.events.save, {
    payload: {
      state,
      networkId,
      signer,
      setup,
      elements,
      dispatch
    }
  })
}
