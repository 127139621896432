import _ from 'lodash'
import { useMemo, useCallback } from 'react'
import { useDataDynamicContext } from '../contexts/DataDynamic'
import { useWeb3Utilities } from './web3'
import { Action } from '@pods-finance/models'

export function useUserActivity () {
  const { signer } = useWeb3Utilities()
  const { activity } = useDataDynamicContext()

  const { elements, status, trackUserActivity: track } = useMemo(
    () => activity,
    [activity]
  )

  const { isLoading, isFinished } = useMemo(() => status, [status])

  const actions = useMemo(() => {
    if (_.isNil(elements)) return []
    return elements.map(item => new Action(item))
  }, [elements])

  const count = useMemo(() => _.get(actions, 'length'), [actions])
  const last = useMemo(() => _.get(actions, actions.length - 1), [actions])
  const more = useCallback(() => {
    if (isFinished) return
    const timestamp = _.get(last, 'timestamp')
    track({
      signer,
      timestamp
    })
  }, [signer, last, isFinished, track])

  return useMemo(
    () => ({
      isLoading,
      isFinished,
      actions,
      count,
      more
    }),
    [isLoading, isFinished, actions, count, more]
  )
}
