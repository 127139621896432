// import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconMenu from '@material-ui/icons/MenuRounded'
import { BoxAction } from '@pods-finance/components'

import Account from '../Account'
import { useUI, useNews } from '../../../hooks'
import AssetLogo from '../../../assets/logo/logo_gradient.svg'
import News from './News'

const WrapperPartial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 13px calc(${props => props.theme.sizes.layoutEdge});
  position: relative;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  margin-bottom: calc(${props => props.theme.sizes.layoutEdge});
`

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1;
`

const Logo = styled.img`
  height: 40px;
  width: auto;
  object-fit: contain;
`

const DrawerTools = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    width: 100%;
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
    height: ${props => props.theme.sizes.navHeight};
    background-color: ${props => props.theme.colors.white};
    border-bottom: 1px solid ${props => props.theme.colors.border};

    & > ${Content} {
      display: none;
    }
    & > ${DrawerTools} {
      display: flex;
      margin-right: auto;
    }
  }

  ${props => props.theme.medias.minMedium} {
    & > * {
      margin-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > p,
  & > a > p {
    margin: 0;
    font-size: 16pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
  }
`

function ToolbarBase ({ className, children }) {
  const { openDrawer } = useUI()
  const news = useNews()
  // const page = usePageByRoute(pages)  · {_.get(page, 'title')}

  return (
    <Wrapper className={className}>
      <DrawerTools>
        <BoxAction Icon={IconMenu} onClick={openDrawer} />
        <Logo src={AssetLogo} />
      </DrawerTools>
      <Content>
        <News {...news} />
        {children}
      </Content>
      <Account />
    </Wrapper>
  )
}

ToolbarBase.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onDrawerOpen: PropTypes.func
}

ToolbarBase.defaultProps = {
  className: null,
  children: null,
  onDrawerOpen: () => {}
}

export default ToolbarBase
