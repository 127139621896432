import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  Modal as Base,
  Checkmark,
  ExternalLink,
  Spinner
} from '@pods-finance/components'
import { useModal } from '@pods-finance/contexts'
import { etherscaned } from '@pods-finance/globals'
import IconArrow from '@material-ui/icons/ArrowForwardRounded'
import IconWarning from '@material-ui/icons/WarningRounded'
import { modals } from '../../../../constants'

import Address from './Address'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 500px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Illustration = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  & > svg {
    font-size: 50pt;
    color: ${props => props.theme.colors.yellow2};
  }
`

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: calc(${props => props.theme.sizes.edge} * 2);
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  & > p {
    color: ${props => props.theme.colors.dark};
    font-size: 18pt;
    font-weight: 700;
    margin: 0;
  }
`

const Data = styled.div`
  width: 100%;
  text-align: center;
  margin-top: calc(${props => props.theme.sizes.edge} * 1.5);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
  color: ${props => props.theme.colors.dark};
  font-size: 11pt;
  font-weight: 600;
  max-width: 400px;
  line-height: 1.5;
`

const Action = styled(ExternalLink)`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.dark};
  border-radius: 6px;
  padding: 12px;
  cursor: pointer;
  transition: background-color 200ms;
  & > p {
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0 4px 0 0;
    transition: color 200ms;
  }
  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
    transition: color 200ms;
  }

  &:hover,
  &:active {
    background-color: ${props =>
      props.theme.isDark
        ? props.theme.colors.platform
        : props.theme.colors.dark};
    transition: background-color 200ms;
    & > p,
    & > svg {
      color: white;
      transition: color 200ms;
    }
  }
`

const OptionWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
`

function TransactionState () {
  const id = modals.transaction
  const { data } = useModal(id)

  const { tx, option, networkId: _networkId, info, state, title } = data || {
    state: 'loading'
  }
  const scanner = etherscaned(tx, _networkId)

  return (
    <Modal id={id} title='' isBackdropDisabled={state === 'loading'}>
      <Content>
        {state === 'loading' && (
          <>
            <Illustration>
              <Loader>
                <Spinner
                  size={110}
                  thickness={2}
                  color={(c, d) => (d ? c.whiteAbolute : c.dark)}
                />
              </Loader>
            </Illustration>
            <Info>
              <Title>
                <p>{title || 'Sending transaction'}</p>
              </Title>
              {info && <Data>{info}</Data>}
              {tx && (
                <Action to={scanner}>
                  <p>View transaction {`${_.toString(tx).substr(0, 9)}...`}</p>
                  <IconArrow />
                </Action>
              )}
            </Info>
          </>
        )}
        {state === 'success' && (
          <>
            <Illustration>
              <Checkmark type='success' />
            </Illustration>
            <Info>
              <Title>
                <p>{title || 'Transaction Successful'}</p>
              </Title>
              {info && <Data>{info}</Data>}
              {option && (
                <OptionWrapper>
                  <Address networkId={_networkId} address={option} />
                </OptionWrapper>
              )}
              {tx && (
                <Action to={scanner}>
                  <p>View transaction {`${_.toString(tx).substr(0, 9)}...`}</p>
                  <IconArrow />
                </Action>
              )}
            </Info>
          </>
        )}

        {state === 'error' && (
          <>
            <Illustration>
              <Checkmark type='error' />
            </Illustration>
            <Info>
              <Title>
                <p>{title || 'Transaction Failed'}</p>
              </Title>
              {info && <Data>{info}</Data>}
              {tx && (
                <Action to={scanner}>
                  <p>View transaction {`${_.toString(tx).substr(0, 9)}...`}</p>
                  <IconArrow />
                </Action>
              )}
            </Info>
          </>
        )}

        {state === 'warning' && (
          <>
            <Illustration>
              <Warning>
                <IconWarning />
              </Warning>
            </Illustration>
            <Info>
              <Title>
                <p>Transaction Postponed</p>
              </Title>
              {info && <Data>{info}</Data>}
              <Action as='div' onClick={() => window.location.reload(true)}>
                <p>Reload the page</p>
                <IconArrow />
              </Action>
            </Info>
          </>
        )}
      </Content>
    </Modal>
  )
}

export default TransactionState
