import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { findPageByRoute } from '@pods-finance/utils'
import { useUI } from '../../../../hooks'
import { pages } from '../../../../constants'

const WrapperPartial = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props =>
    props.theme.isDark
      ? props.theme.colors.platform
      : props.theme.colors.white};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  padding: ${props => props.theme.sizes.edge};
  height: 54px;
  width: 54px;
  transition: box-shadow 150ms, border 150ms;
  cursor: pointer;
`

const IconWrapper = styled.div`
  /* margin-right: calc(${props => props.theme.sizes.edge}); */
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    font-size: 16pt;
    fill: ${props =>
      props.theme.isDark
        ? props.theme.colors.dark
        : props.theme.colors.content};
  }
`

const Title = styled.div`
  font-size: 11pt;
  font-weight: 700;
  color: ${props => props.theme.colors.dark};
  margin: 0 0 0 16px;
  display: none;
`

const Wrapper = styled(WrapperPartial)`
  &.active {
    cursor: default;
    box-shadow: 0 15px 48px -12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 250ms, border 250ms;

    border: 1px solid ${props =>
      props.theme.isDark
        ? props.theme.colors.border
        : props.theme.colors.border};

    & > ${IconWrapper} > svg{
          fill: url("${props => props.theme.fills.primary}") ${props =>
  props.theme.colors.secondary};

      }

      ${Title}{
        color: ${props =>
          props.theme.isDark
            ? props.theme.colors.middle
            : props.theme.colors.middle};
      }

  }

  &:not(.active){
    &:hover, &:active{
      box-shadow: 0 15px 48px -12px rgba(0, 0, 0, 0.1);
      border: 1px solid ${props =>
        props.theme.isDark
          ? props.theme.colors.borderMedium
          : props.theme.colors.border};
      transition: box-shadow 250ms, border 250ms;
    }
  }

  ${props => props.theme.medias.medium} {
    padding: calc(${props => props.theme.sizes.edge} * 1);
    border-radius: calc(${props => props.theme.sizes.edge} * 1 / 3);
    &:hover, &:active{
      border: 1px solid ${props => props.theme.colors.platform};
    }
    ${Title}{
      display: flex;
      font-weight: 600;
      padding-left: 20px;
    }

  }
`

function MenuItem ({ Icon, builder, route, title }) {
  const { closeDrawer } = useUI()
  const to = useMemo(() => builder(), [builder])

  return (
    <Wrapper
      exact
      to={to}
      onClick={closeDrawer}
      isActive={(match, location) => {
        const page = findPageByRoute(_.get(location, 'pathname'), pages)
        if (!_.isNil(page) && page.route === route) return true
        return false
      }}
    >
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Title>{title}</Title>
    </Wrapper>
  )
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  isActive: PropTypes.bool
}

MenuItem.defaultProps = {
  isActive: false
}

export default MenuItem
