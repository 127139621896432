import _ from 'lodash'
import React, { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import IconLock from '@material-ui/icons/LockRounded'

const WrapperPartial = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px 8px 0 0;
  height: 100%;
  box-shadow: none;
  transition: box-shadow 150ms;

  &:after {
    display: none;
    content: '';
    left: 0;
    bottom: 0;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: ${props => props.theme.colors.dark};
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 calc(${props => props.theme.sizes.edge} * 1);
  min-width: 120px;
  & > svg {
    font-size: 15pt;
    margin: 0 8px 0 0;
    color: ${props => props.theme.colors.contentMedium};
  }
`

const Locker = styled.div`
  height: 21px;
  width: 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.platform};
  margin-left: 6px;
  display: none;
  & > svg {
    font-size: 10pt;
    color: ${props => props.theme.colors.contentMedium};
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  & > p {
    font-size: 12pt;
    font-weight: 700;
    margin: 0;
    color: ${props => props.theme.colors.contentMedium};
  }
`

const Subtitle = styled.p`
  font-size: 9pt;
  font-weight: 600;
  margin: 2px 0 0 0;
  color: ${props => props.theme.colors.contentMedium};
  white-space: nowrap;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Wrapper = styled(WrapperPartial)`
  &[data-active='true'] {
    &:after {
      display: flex;
    }

    ${Inner} {
      & > svg,
      ${Title} > p,
      ${Subtitle} {
        color: ${props => props.theme.colors.dark};
      }
    }
  }

  &[data-active='false'][data-locked='false'] {
    cursor: pointer;
    &:hover,
    &:active {
      box-shadow: ${props => props.theme.styles.boxShadowInset};
      transition: box-shadow 150ms;
    }
  }

  &[data-locked='true'] {
    user-select: none;
    ${Title} > ${Locker}{
      display: flex;
    }
  }

  ${props => props.theme.medias.medium} {
    ${Inner}{
      min-width: 80px;
    }

  }
`

function TabItem ({ Icon, title, hash, subtitle, isLocked }) {
  const location = useLocation()
  const history = useHistory()

  const isActive = useMemo(() => _.get(location, 'hash') === `#${hash}`, [
    location,
    hash
  ])

  const onClick = useCallback(() => {
    if (!isActive) {
      history.replace({
        pathname: location.pathname,
        hash,
        search: location.search
      })
    }
  }, [isActive, history, hash, location])

  return (
    <Wrapper data-active={isActive} data-locked={isLocked} onClick={onClick}>
      <Inner>
        <Icon />
        <Content>
          <Title>
            <p>{title}</p>
            <Locker>
              <IconLock />
            </Locker>
          </Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Content>
      </Inner>
    </Wrapper>
  )
}

TabItem.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  isLocked: PropTypes.bool,
  subtitle: PropTypes.string,
  onLockedClick: PropTypes.func
}

TabItem.defaultProps = {
  isLocked: false,
  subtitle: null,
  onLockedClick: null
}

export default TabItem
