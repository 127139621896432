import _ from 'lodash'
import { useMemo } from 'react'
import numeral from 'numeral'
import { useHistory } from 'react-router-dom'
import { attributes, macros } from '@pods-finance/globals'
import {
  toQuantity,
  toNumeralPrice,
  beautifyAddress
} from '@pods-finance/utils'
import { useOptions, useOptionsDynamics } from '../data'
import { useAccount } from '../web3'
import { useWatched } from '../ui'
import { pages, uuidify } from '../../constants'

export default function useWatchedTable () {
  const history = useHistory()
  const { isConnected } = useAccount()
  const { watched } = useWatched()
  const { options: raw, isLoading: isLoadingOptions } = useOptions()
  const {
    itemizer,
    isLoadingGeneral: isLoadingGeneralDynamics,
    isLoadingUser: isLoadingUserDynamics
  } = useOptionsDynamics()

  const options = useMemo(() => {
    return raw.filter(option =>
      watched
        .map(w => uuidify(_.get(w, 'address'), _.get(w, 'networkId')))
        .includes(_.get(option, 'uuid'))
    )
  }, [watched, raw])

  const columns = useMemo(
    () => [
      {
        title: 'Option',
        layout: attributes.layout.cell.Pod,
        weight: 2
      },
      {
        title: 'Network',
        layout: attributes.layout.cell.Network,
        weight: 1
      },

      {
        title: 'Supply',
        layout: attributes.layout.cell.Text,
        weight: 1
      },
      {
        title: 'Your balance',
        layout: attributes.layout.cell.Text,
        weight: 1
      },
      {
        title: 'Address',
        layout: attributes.layout.cell.Text,
        weight: 1
      },

      {
        title: '',
        layout: attributes.layout.cell.Actions
      }
    ],
    []
  )

  const instructions = useMemo(
    () => ({
      onRowClick: params => {
        history.push(pages.option.builder(_.get(params, 'id')))
      }
    }),
    [history]
  )

  const rows = useMemo(
    () =>
      options.map(option => {
        const durations = option.getDurations()
        const dynamics = itemizer(option.uuid)

        return {
          id: option.uuid,
          cells: [
            {
              tokens: [option.underlying, option.strike],
              classification: option.isPut() ? 'Put' : 'Call',
              localization:
                parseInt(option.exerciseType) === macros.EXERCISE_TYPE.european
                  ? 'European'
                  : 'American',
              strikePrice: numeral(option.strikePrice.humanized).format(
                '$0.[0000]'
              ),
              expiration: durations.expirationFormattedWithHour
            },
            {
              value: option.networkId
            },
            {
              value: toQuantity(
                toNumeralPrice(_.get(dynamics, 'totalSupply.humanized'), false),
                'option'
              ),
              isLoading: isLoadingGeneralDynamics
            },
            {
              value: toQuantity(
                toNumeralPrice(
                  _.get(dynamics, 'userOptionBalance.humanized'),
                  false
                ),
                'option'
              ),
              isAccent: true,
              isLoading: isLoadingUserDynamics,
              isUnknown: !isConnected
            },
            {
              value: beautifyAddress(option.address, 4, 6)
            },
            {
              durations,
              value: option.uuid,
              source: pages.dashboard.builder(),
              isLabeled: false
            }
          ]
        }
      }),
    [
      options,
      itemizer,
      isConnected,
      isLoadingGeneralDynamics,
      isLoadingUserDynamics
    ]
  )

  return {
    data: {
      columns,
      rows,
      instructions,
      /** Cosmetics */
      isLoading: isLoadingOptions,
      expected: 2
    }
  }
}
