import { networks } from '@pods-finance/globals'
import { findPageByRoute } from '@pods-finance/utils'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import * as Modals from '../../components/specific/Modals'
import { pages } from '../../constants'
import { useAccount } from '../../hooks'

const Wrapper = styled.div``

function Watcher () {
  const location = useLocation()
  const history = useHistory()
  const { networkId, isConnected } = useAccount()

  useEffect(() => {
    if (isConnected) {
      const current = findPageByRoute(location.pathname, pages)
      if (
        current.route !== pages.unsupported.route &&
        !networks._supported_factory.includes(networkId)
      ) {
        history.replace(pages.unsupported.route)
      } else {
        if (
          current.route === pages.unsupported.route &&
          networks._supported_factory.includes(networkId)
        ) {
          history.replace(pages.dashboard.route)
        }
      }
    }
  }, [isConnected, networkId, history, location])

  return (
    <Wrapper>
      <Modals.ManageWatched />
      <Modals.TransactionState />
    </Wrapper>
  )
}

export default Watcher
