import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { networks } from '@pods-finance/globals'
import { Spinner } from '@pods-finance/components'
import { Structure, Section, Toolbar } from '../../components/common'
import { useAccount, useNetworkId, useUserCrafted } from '../../hooks'

import * as Sections from '../../components/specific/Profile'

const Canvas = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium} * 2);
  & > * {
    padding-right: calc(${props => props.theme.sizes.edge} * 2);
    &:last-child {
      padding-right: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    padding: 0;
    flex-direction: column;
    & > * {
      padding-right: 0;
    }
  }
`

function Profile () {
  const { isConnected, isExpected } = useAccount()
  const networkId = useNetworkId()

  const { isLoading } = useUserCrafted()

  const networkName = useMemo(
    () => _.get(networks, `_data[${networkId}].name`),
    [networkId]
  )

  return (
    <Structure>
      <Toolbar />
      <Section
        title='Wallet factory'
        description={`See all the option you have created from the connected wallet on ${networkName}.`}
      >
        <Canvas>
          {isExpected ? (
            !isConnected || isLoading ? (
              <Spinner />
            ) : (
              <Sections.Crafted />
            )
          ) : (
            <p>Connect your wallet to view the crafted options.</p>
          )}
        </Canvas>
      </Section>
      <Section
        title='Wallet activity'
        description={`See the wallet's activity history from ${networkName}.`}
      >
        <Canvas>
          {isExpected ? (
            !isConnected ? (
              <Spinner />
            ) : (
              <Sections.Activity />
            )
          ) : (
            <p>Connect your wallet to view the activity history.</p>
          )}
        </Canvas>
      </Section>
    </Structure>
  )
}

export default Profile
