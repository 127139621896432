import _ from 'lodash'
import gql from 'graphql-tag'
import SDK from '@pods-finance/sdk'
import { networks, macros } from '@pods-finance/globals'

import { sleep } from '@pods-finance/utils'
import { getClient } from '../../apollo/client'
import { getInfuraProvider } from '../Web3/config'
import { uuidify } from '../../constants'

function customizer (key = 'id') {
  return function (addition, source) {
    if (_.isArray(addition)) {
      return _.uniqBy(addition.concat(source), item => item[key])
    }
  }
}

export async function updateGeneralDynamics ({ dispatch, options }) {
  if (
    _.isNil(options) ||
    !options.every(
      option =>
        _.isNil(option) || _.isNil(option.uuid) || _.isNil(option.netwroKId)
    )
  ) {
    console.error('Dynamic Data: options misconfigured.')
    return
  }

  if (!options.length) return

  try {
    dispatch(['_status', { isLoading: true, warning: null }], 'DEEP_UPDATE')

    console.info('[ ---- ] Requesting dynamics')

    const queries = networks._supported_factory.map(networkId => {
      const list = options.filter(
        option => _.get(option, 'networkId') === networkId
      )
      return async () => {
        try {
          const result = await SDK.Multicall.getGeneralDynamics({
            provider: getInfuraProvider(networkId),
            options: list.map(option => option.fromSDK()),
            includePool: false
          })
          return [networkId, result]
        } catch (e) {
          return [networkId, null]
        }
      }
    })

    const list = await Promise.all(queries.map(q => q()))

    const flatten = list
      .filter(result => result[1] !== null)
      .map(result =>
        Object.keys(result[1]).map(address => ({
          address,
          networkId: result[0],
          uuid: uuidify(address, result[0]),
          ...(result[1][address] || {})
        }))
      )
      .reduce((p, c) => (c && c.length ? [...p, ...c] : p), [])

    if (flatten && flatten.length) {
      if (flatten.length !== options.length) {
        dispatch(
          options
            .filter(o => !flatten.map(f => f.uuid).includes(o.uuid))
            .map(option => [
              option.uuid,
              {
                value: null,
                warning: 'Option not found.'
              }
            ]),

          'MULTI_DEEP_UPDATE'
        )
      }

      dispatch(
        [
          ...flatten.map(option => [
            option.uuid,
            {
              value: option
            }
          ]),
          [
            '_status',
            {
              isLoading: false,
              warning: null
            }
          ]
        ],
        'MULTI_DEEP_UPDATE'
      )
    } else throw new Error('Options could not be found.')
  } catch (error) {
    console.error('Data:', error)

    dispatch(
      [
        ...options.map(
          option => [
            option.uuid,
            {
              value: null
            }
          ],
          [
            '_status',
            {
              isLoading: false,
              warning: error.message
            }
          ]
        )
      ],
      'MULTI_DEEP_UPDATE'
    )
  }
}

export async function updateUserDynamics ({ dispatch, options, signer }) {
  if (
    _.isNil(options) ||
    !options.every(
      option =>
        _.isNil(option) || _.isNil(option.uuid) || _.isNil(option.netwroKId)
    )
  ) {
    console.error('Dynamic Data: options misconfigured.')
    return
  }

  if (!options.length) return

  try {
    dispatch(['_status', { isLoading: true, warning: null }], 'DEEP_UPDATE')

    console.info('[ ---- ] Requesting dynamics')

    const queries = networks._supported_factory.map(networkId => {
      const list = options.filter(
        option => _.get(option, 'networkId') === networkId
      )
      return async () => {
        try {
          const user = await signer.getAddress()
          const result = await SDK.Multicall.getUserDynamics({
            user,
            provider: getInfuraProvider(networkId),
            options: list.map(option => option.fromSDK()),
            includePool: false
          })
          return [networkId, result]
        } catch (e) {
          return [networkId, null]
        }
      }
    })

    const list = await Promise.all(queries.map(q => q()))

    const flatten = list
      .filter(result => result[1] !== null)
      .map(result =>
        Object.keys(result[1]).map(address => ({
          address,
          networkId: result[0],
          uuid: uuidify(address, result[0]),
          ...(result[1][address] || {})
        }))
      )
      .reduce((p, c) => (c && c.length ? [...p, ...c] : p), [])

    if (flatten && flatten.length) {
      if (flatten.length !== options.length) {
        dispatch(
          options
            .filter(o => !flatten.map(f => f.uuid).includes(o.uuid))
            .map(option => [
              option.uuid,
              {
                value: null,
                warning: 'Option not found.'
              }
            ]),

          'MULTI_DEEP_UPDATE'
        )
      }

      dispatch(
        [
          ...flatten.map(option => [
            option.uuid,
            {
              value: option
            }
          ]),
          [
            '_status',
            {
              isLoading: false,
              warning: null
            }
          ]
        ],
        'MULTI_DEEP_UPDATE'
      )
    } else throw new Error('Options could not be found.')
  } catch (error) {
    console.error('Data:', error)

    dispatch(
      [
        ...options.map(
          option => [
            option.uuid,
            {
              value: null
            }
          ],
          [
            '_status',
            {
              isLoading: false,
              warning: error.message
            }
          ]
        )
      ],
      'MULTI_DEEP_UPDATE'
    )
  }
}

export async function updateUserActivity ({
  delay,
  dispatch,
  elements,
  signer,
  timestamp
}) {
  if (_.isNil(signer)) {
    console.error('Activity: user misconfigured.')
    return
  }

  try {
    dispatch(
      [elements._status, { isLoading: true, warning: null }],
      'DEEP_UPDATE'
    )

    console.info('[ ---- ] Requesting user activity')

    const user = await signer.getAddress()
    const networkId = await signer.getChainId()

    if (delay) await sleep(delay) // Because the subgraph takes time to update, we'll add a fake delay to these queries

    const activity = await SDK.ActionBuilder.fromUser({
      user,
      networkId,
      client: getClient(networkId),
      first: macros.ACTIVITY_PAGINATOR,
      timestamp
    })

    if (activity && activity.length) {
      dispatch(
        [
          [elements.store, { value: activity }, customizer('id')],
          [
            elements._status,
            {
              isLoading: false,
              isFinished:
                activity && activity.length < macros.ACTIVITY_PAGINATOR
            }
          ]
        ],
        'MULTI_DEEP_UPDATE'
      )
    } else {
      dispatch(
        [
          elements._status,
          {
            isLoading: false,
            isFinished: true,
            warning: 'No more activity for this user.'
          }
        ],
        'DEEP_UPDATE'
      )
    }
  } catch (error) {
    console.error(error)
    dispatch(
      [
        elements._status,
        {
          isLoading: false,
          isFinished: true,
          warning: error.message
        }
      ],
      'DEEP_UPDATE'
    )
  }
}

export async function updateUserCrafted ({
  delay,
  dispatch,
  elements,
  signer
}) {
  if (_.isNil(signer)) {
    console.error('Crafted: user misconfigured.')
    return
  }

  try {
    dispatch(
      [elements._status, { isLoading: true, warning: null }],
      'DEEP_UPDATE'
    )

    console.info('[ ---- ] Requesting user crafted')

    const user = await signer.getAddress()
    const networkId = await signer.getChainId()

    if (delay) await sleep(delay) // Because the subgraph takes time to update, we'll add a fake delay to these queries

    const { OptionFragment } = SDK.queries.atoms

    const GET_OPTIONS = gql`
      query options($user: Bytes!, $optionTypes: [Int!]!) {
        options(
          orderBy: expiration
          orderDirection: desc
          where: { from: $user, type_in: $optionTypes }
        ) {
          ...OptionFragment
        }
      }

      ${OptionFragment}
    `

    const query = await getClient(networkId).query({
      query: GET_OPTIONS,
      variables: {
        user: user.toLowerCase(),
        optionTypes: [macros.OPTION_TYPE.put, macros.OPTION_TYPE.call]
      },
      fetchPolicy: 'no-cache'
    })

    const options = _.get(query, 'data.options')

    if (options && options.length) {
      dispatch(
        [
          [
            elements.store,
            {
              value: options.map(o =>
                SDK.OptionBuilder.fromData({ source: o, networkId })
              )
            },
            customizer('uuid')
          ],
          [
            elements._status,
            {
              isLoading: false,
              isFinished: true
            }
          ]
        ],
        'MULTI_DEEP_UPDATE'
      )
    } else {
      dispatch(
        [
          elements._status,
          {
            isLoading: false,
            isFinished: true,
            warning: 'No options crafted by this user.'
          }
        ],
        'DEEP_UPDATE'
      )
    }
  } catch (error) {
    console.error(error)
    dispatch(
      [
        elements._status,
        {
          isLoading: false,
          isFinished: true,
          warning: error.message
        }
      ],
      'DEEP_UPDATE'
    )
  }
}
