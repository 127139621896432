import _ from 'lodash'
import { useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useAtomicMachine } from '@pods-finance/hooks'
import { guards, handleTransactionReason } from '@pods-finance/utils'

import { useModal } from '@pods-finance/contexts'
import { words } from '@pods-finance/globals'
import { modals } from '../../constants'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const payload = _.get(context, 'payload') || {}
      const { option, setup } = payload

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: 'Withdrawing tokens and unlocking colllateral.'
        })

        let rejected = null
        const { helper } = setup

        /**
         * Always send the SDK provided _element back to the SDK
         */

        await helper.doWithdraw({
          option: option.fromSDK(),
          overrides: {
            gasLimit: true
          },
          callback: (error, transactionHash) => {
            if (error) rejected = error
            updateData({
              tx: transactionHash
            })
          }
        })

        if (!_.isNil(rejected)) throw rejected

        removeAllToasts()

        updateData({
          state: 'success',
          info: 'Successfully withdrawn.'
        })

        addToast('Successfully withdrawn!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        console.error('Withdraw', e)
        removeAllToasts()

        const reason = handleTransactionReason(_.get(e, 'code'))

        updateData({
          title: reason,
          state: 'error',
          info: words.errorTransactionFailed(
            words.errorTransactionFailed('Attempted to withdraw.')
          )
        })

        addToast(reason, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        setup.update()
        throw e
      }
      setup.update()
    },
    [addToast, removeAllToasts, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'withdraw',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
