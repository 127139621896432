import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'
import { useClipboard } from 'use-clipboard-copy'
import IconClipboard from '@material-ui/icons/ShareRounded'
import IconSuccess from '@material-ui/icons/CheckRounded'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
  margin-top: calc(${props => props.theme.sizes.edge} * 1);
`

export const Header = styled.div`
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.layoutEdge} + 2px);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > p,
  & > a > p {
    margin: 0;
    font-size: 26pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
  }

  &[data-copy='true'] {
    & > p,
    & > a > p {
      margin-right: 10px;
    }
  }
`

export const Description = styled.p`
  margin: 0;
  margin-top: calc(${props => props.theme.sizes.edge} * 1.5);
  color: ${props =>
    props.theme.isDark
      ? props.theme.colors.whiteAbsolute
      : props.theme.colors.contentMedium};
  line-height: 1.5;
  font-size: 11pt;
  font-weight: 600;
`

export const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 0);
    margin-top: calc(${props => props.theme.sizes.edge} * 2);
    ${Header} {
      padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
    }
    ${Title} {
      p,
      a {
        font-size: 20pt;
      }
    }
  }
`

const CopyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -2px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${props => props.theme.colors.border};
  cursor: pointer;
  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.contentMedium};
  }
  &:hover,
  &:active,
  &[data-active='ture'] {
    & > svg {
      color: ${props => props.theme.colors.dark};
    }
  }
`

function Section ({ children, className, title, description, hash, copy }) {
  const clipboard = useClipboard({ copiedTimeout: 2000 })

  const { addToast, removeAllToasts } = useToasts()

  return (
    <Wrapper className={className} id={hash}>
      {(!_.isNil(title) || !_.isNil(description)) && (
        <Header data-component='header'>
          {!_.isNil(title) && (
            <>
              <Title data-component='title' data-copy={!_.isNil(copy)}>
                <p>{title}</p>
                {!_.isNil(copy) && (
                  <CopyWrapper
                    data-active={clipboard.copied}
                    title='Copy the query link for the option results below.'
                    onClick={() => {
                      clipboard.copy(copy)
                      removeAllToasts()
                      addToast(
                        'Query link copied to your clipboard. Use it to share this option combination!',
                        {
                          appearance: 'success',
                          autoDismiss: true,
                          autoDismissTimeout: 7000
                        }
                      )
                    }}
                  >
                    {clipboard.copied ? <IconSuccess /> : <IconClipboard />}
                  </CopyWrapper>
                )}
              </Title>
            </>
          )}

          {!_.isNil(description) && (
            <Description data-component='description'>
              {description}
            </Description>
          )}
        </Header>
      )}
      {children}
    </Wrapper>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  hash: PropTypes.string,
  copy: PropTypes.string
}

Section.defaultProps = {
  className: null,
  children: null,
  description: null,
  hash: null,
  copy: null
}

export default Section
