import _ from 'lodash'
import React, { createContext, useContext, useMemo } from 'react'

import { macros } from '@pods-finance/globals'

import * as config from './config'

export const Context = createContext({
  account: null,
  modal: null,
  connect: () => {},
  provider: null,
  signer: null
})

export default function Provider ({ children }) {
  const { state, connect, disconnect } = config.useSetup()

  const networkId = useMemo(() => {
    if (_.isNil(state.networkId)) return macros.DEFAULT_NETWORK_ID
    return state.networkId
  }, [state.networkId])

  const context = useMemo(
    () => ({
      ...state,
      networkId,
      connect,
      disconnect
    }),
    [networkId, state, connect, disconnect]
  )

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export function useWeb3Context () {
  return useContext(Context)
}

export const useWeb3Emergency = config.useWeb3Emergency
