import _ from 'lodash'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Input, Label, Button as ButtonPartial } from '@pods-finance/components'
import { useModal } from '@pods-finance/contexts'
import { useNetworkId, useFormValidator, useWatched } from '../../../../hooks'
import reducers from '../../../../reducers'
import machines from '../../../../machines'
import { modals } from '../../../../constants'
import * as logic from './logic'

const Wrapper = styled.div`
  width: 100%;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  ${props => props.theme.medias.medium} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

const Form = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 400px;
  grid-gap: calc(${props => props.theme.sizes.edge} * 1);
  padding: calc(${props => props.theme.sizes.edge} * 1);

  border-radius: calc(${props => props.theme.sizes.edge} * 1);
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};

  ${props => props.theme.medias.medium} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0;
  }

  ${props => props.theme.medias.small} {
    grid-template-columns: 1fr;
    grid-gap: calc(${props => props.theme.sizes.edge} * 0);
  }
`
const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: calc(${props => props.theme.sizes.edge} * 1);
`

const Cell = styled.div`
  grid-column: span 1fr;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Button = styled(ButtonPartial)`
  border-radius: 8px;
  min-height: 53px;
`

export default function Filters () {
  const networkId = useNetworkId()
  const machine = machines.watcher.useMachine()
  const { setOpen: setManageOpen } = useModal(modals.manageWatched)
  const { elements, state, dispatch } = reducers.watcher.useReducer()

  const { watched, addWatched } = useWatched()

  const onChangeAddress = useCallback(
    value => {
      logic.onChangeAddress({ value, elements, dispatch })
    },
    [elements, dispatch]
  )

  const onChangeNetwork = useCallback(
    value => {
      if (value !== _.get(state, 'network.value')) {
        logic.onChangeNetwork({ value, elements, dispatch })
      }
    },
    [elements, state, dispatch]
  )

  const onTransact = useCallback(
    () =>
      logic.onTransact({
        machine,
        state,
        elements,
        dispatch,
        watched,
        addWatched
      }),
    [machine, state, elements, dispatch, watched, addWatched]
  )

  const { isValid } = useFormValidator({
    state,
    machine
  })

  return (
    <Wrapper>
      <Content>
        <Form>
          <Cell>
            <Label>Option</Label>
            <Input.Text
              {...state.address}
              networkId={networkId}
              placeholder='Enter option address'
              onChange={e => {
                onChangeAddress(_.get(e, 'target.value'), null)
              }}
            />
          </Cell>
          <Cell>
            <Label>Network</Label>
            <Input.Network
              {...state.network}
              networkId={networkId}
              placeholder='Mainnet'
              onChange={id => {
                onChangeNetwork(id, null)
              }}
            />
          </Cell>
          <Cell>
            <Label>Actions</Label>
            <Row>
              <Button
                title='Start watching'
                titleShort='Watch'
                appearance={a => a.gradient}
                accent={a => a.primary}
                type={t => t.button}
                isDisabled={!isValid}
                onClick={() => onTransact()}
              />
              <Button
                title={`Manage${watched.length ? ` (${watched.length})` : ''}`}
                appearance={a => a.outline}
                accent={a => a.middle}
                type={t => t.button}
                onClick={() => setManageOpen(true)}
              />
            </Row>
          </Cell>
        </Form>
      </Content>
    </Wrapper>
  )
}
