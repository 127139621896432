import React from 'react'
import styled from 'styled-components'
import FactoryIllustration from '../../../../assets/illustrations/FactoryDown.png'
import { Button } from '@pods-finance/components'
import { pages } from '../../../../constants'

const Wrapper = styled.div``

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${props => props.theme.sizes.layoutEdge};
  & > * {
    margin-bottom: ${props => props.theme.sizes.edge};
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    padding: 0 ${props => props.theme.sizes.layoutEdgeMedium};
  }
`

const Box = styled.div`
  height: 360px;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid ${props => props.theme.colors.border};
  overflow: hidden;
  ${props => props.theme.medias.medium} {
    height: auto;
  }
`
const Row = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.sizes.layoutEdge};
  ${props => props.theme.medias.medium} {
    padding: 0;
  }
`

const Left = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${props => props.theme.sizes.layoutEdge};
  text-align: left;
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Right = styled.div`
  height: 100%;
  width: 500px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  ${props => props.theme.medias.medium} {
    display: none;
  }
`

const Asset = styled.img`
  position: absolute;
  bottom: -100px;
  width: 500px;
  object-fit: contain;
  object-position: right bottom;
`

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  & > p,
  & > a > p {
    margin: 0;
    font-size: 26pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
  }

  &[data-copy='true'] {
    & > p,
    & > a > p {
      margin-right: 10px;
    }
  }
`

export const Description = styled.div`
  & > p {
    margin: 0;
    color: ${props => props.theme.colors.dark};
    line-height: 1.5;
    font-size: 11pt;
    font-weight: 600;
  }
`

function Overview () {
  return (
    <Wrapper>
      <Content>
        <Box>
          <Row>
            <Left>
              <Title>
                <p>Airdrip Dashboard</p>
              </Title>
              <Description>
                <p>
                  Unlock options for smart liquidity mining, vesting campaigns
                  and more.
                </p>
              </Description>
              <Button
                accent={a => a.primary}
                appearance={a => a.gradient}
                type={t => t.router}
                title='Deploy option'
                to={pages.craft.route}
              />
            </Left>
            <Right>
              <Asset src={FactoryIllustration} />
            </Right>
          </Row>
        </Box>
      </Content>
    </Wrapper>
  )
}

export default Overview
