import _ from 'lodash'
import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useEffect
} from 'react'

import { macros, networks } from '@pods-finance/globals'

import {
  useNetworkId,
  useOptions,
  useVersion,
  useWeb3Utilities
} from '../../hooks'

import reducers from '../../reducers'
import * as helpers from './helpers'

const initial = {
  uuid: null,
  value: null,
  warning: null,
  isCurated: true
}

export const DataDynamicContext = createContext(null)

export default function Provider ({ children }) {
  const general = useGeneralDynamics()
  const user = useUserDynamics()
  const activity = useUserActivity()
  const crafted = useUserCrafted()

  return (
    <DataDynamicContext.Provider value={{ activity, user, crafted, general }}>
      {children}
    </DataDynamicContext.Provider>
  )
}

export function useDataDynamicContext () {
  return useContext(DataDynamicContext)
}

function useGeneralDynamics () {
  const networkId = useNetworkId()
  const { version } = useVersion()
  const { options, warning, isLoading } = useOptions()
  const { state, dispatch } = reducers.light.useReducer()

  const updateGeneralDynamics = useCallback(
    ({ options }) => {
      helpers.updateGeneralDynamics({ options, dispatch })
    },
    [dispatch]
  )

  const trackGeneralDynamics = useCallback(
    ({ options, reset = false, isCurated = true }) => {
      if (reset) dispatch([], 'RESET')
      if (_.isNil(options) || !options.length) return

      const shape = option => {
        const item = _.cloneDeep(initial)
        item.uuid = option.uuid
        item.isCurated = isCurated
        return item
      }

      dispatch(
        options.map(o => [o.uuid, shape(o)]),
        'MULTI_SET'
      )

      updateGeneralDynamics({ options })
    },
    [dispatch, updateGeneralDynamics]
  )

  useEffect(() => {
    if (
      isLoading ||
      !_.isNilOrEmptyString(warning) ||
      !networks._supported_factory.includes(networkId)
    ) {
      return
    }
    if (options && options.length) {
      trackGeneralDynamics({
        options,
        reset: true
      })
    }
  }, [options, warning, isLoading, version, networkId, trackGeneralDynamics])

  const elements = useMemo(
    () =>
      Object.keys(state || {})
        .filter(key => key !== '_status')
        .map(key => state[key]),
    [state]
  )

  const status = useMemo(() => _.get(state, '_status'), [state])

  return {
    elements,
    status,
    trackGeneralDynamics,
    updateGeneralDynamics
  }
}

function useUserDynamics () {
  const { version } = useVersion()
  const { signer, networkId } = useWeb3Utilities()
  const { options, warning, isLoading } = useOptions()
  const { state, dispatch } = reducers.light.useReducer()

  const updateUserDynamics = useCallback(
    ({ options, signer }) => {
      helpers.updateUserDynamics({ options, signer, dispatch })
    },
    [dispatch]
  )

  const trackUserDynamics = useCallback(
    ({ options, signer, reset = false, isCurated = true }) => {
      if (reset) dispatch([], 'RESET')
      if (_.isNil(options) || !options.length) return

      const shape = option => {
        const item = _.cloneDeep(initial)
        item.uuid = option.uuid
        item.isCurated = isCurated
        return item
      }

      dispatch(
        options.map(o => [o.uuid, shape(o)]),
        'MULTI_SET'
      )

      updateUserDynamics({ options, signer })
    },
    [dispatch, updateUserDynamics]
  )

  useEffect(() => {
    dispatch(['_status', { isLoading: false }])
  }, [dispatch])

  useEffect(() => {
    if (
      isLoading ||
      !_.isNilOrEmptyString(warning) ||
      _.isNil(signer) ||
      !networks._supported_factory.includes(networkId)
    ) {
      return
    }
    if (options && options.length) {
      trackUserDynamics({
        options,
        signer,
        reset: true
      })
    }
  }, [
    options,
    warning,
    isLoading,
    signer,
    version,
    networkId,
    trackUserDynamics
  ])

  const elements = useMemo(
    () =>
      Object.keys(state || {})
        .filter(key => key !== '_status')
        .map(key => state[key]),
    [state]
  )

  const status = useMemo(() => _.get(state, '_status'), [state])

  return {
    elements,
    status,
    trackUserDynamics,
    updateUserDynamics
  }
}

function useUserActivity () {
  const { version } = useVersion()
  const { provider, signer, networkId } = useWeb3Utilities()
  const { elements, state, dispatch } = reducers.store.useReducer()

  /**
   * -------------------------------
   * User Activity
   * -------------------------------
   */

  const updateUserActivity = useCallback(
    ({ signer, delay, timestamp }) => {
      helpers.updateUserActivity({
        delay,
        timestamp,
        signer,
        dispatch,
        elements
      })
    },
    [dispatch, elements]
  )
  const trackUserActivity = useCallback(
    ({ signer, provider, timestamp, delay = 0, reset = false }) => {
      if (reset) dispatch([], 'RESET', [])
      updateUserActivity({ signer, provider, delay, timestamp })
    },
    [dispatch, updateUserActivity]
  )

  useEffect(() => {
    if (
      _.isNil(signer) ||
      _.isNil(provider) ||
      !networks._supported_factory.includes(networkId)
    ) {
      return
    }
    if (provider && signer) {
      const delay = version === 0 ? 0 : macros.SUBGRAPH_REQUIRY_DELAY
      trackUserActivity({
        provider,
        signer,
        delay,
        reset: true,
        timestamp: Math.floor(Date.now() / 1000) + 1
      })
    }
  }, [signer, provider, networkId, version, trackUserActivity])

  const items = useMemo(() => _.get(state, 'store.value') || [], [state])

  const status = useMemo(() => _.get(state, '_status'), [state])

  return useMemo(
    () => ({
      elements: items,
      status,

      trackUserActivity,
      updateUserActivity
    }),
    [items, status, trackUserActivity, updateUserActivity]
  )
}

function useUserCrafted () {
  const { version } = useVersion()
  const { provider, signer, networkId } = useWeb3Utilities()
  const { elements, state, dispatch } = reducers.store.useReducer()

  /**
   * -------------------------------
   * User Activity
   * -------------------------------
   */

  const updateUserCrafted = useCallback(
    ({ signer, delay }) => {
      helpers.updateUserCrafted({
        delay,
        signer,
        dispatch,
        elements
      })
    },
    [dispatch, elements]
  )
  const trackUserCrafted = useCallback(
    ({ signer, provider, delay = 0, reset = false }) => {
      if (reset) dispatch([], 'RESET', [])
      updateUserCrafted({ signer, provider, delay })
    },
    [dispatch, updateUserCrafted]
  )

  useEffect(() => {
    if (
      _.isNil(signer) ||
      _.isNil(provider) ||
      !networks._supported_factory.includes(networkId)
    ) {
      return
    }
    if (provider && signer) {
      const delay = version === 0 ? 0 : macros.SUBGRAPH_REQUIRY_DELAY
      trackUserCrafted({
        provider,
        signer,
        delay,
        reset: true
      })
    }
  }, [signer, provider, networkId, version, trackUserCrafted])

  const items = useMemo(() => _.get(state, 'store.value') || [], [state])

  const status = useMemo(() => _.get(state, '_status'), [state])

  return useMemo(
    () => ({
      elements: items,
      status,

      trackUserCrafted,
      updateUserCrafted
    }),
    [items, status, trackUserCrafted, updateUserCrafted]
  )
}
