import { networks } from '@pods-finance/globals'
import { useAtomicReducer } from '@pods-finance/hooks'

const initial = {
  address: {
    value: null,
    warning: null,
    isPrimary: true
  },
  network: {
    value: networks.mainnet,
    source: networks._supported_factory,
    warning: null,
    isPrimary: true
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
