import React from 'react'
import styled from 'styled-components'
import { Structure, Section, Toolbar } from '../../components/common'
import * as Sections from '../../components/specific/Craft'

const Canvas = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium} * 2);
  & > * {
    padding-right: calc(${props => props.theme.sizes.edge} * 2);
    &:last-child {
      padding-right: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    flex-direction: column;
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium} * 1);
    & > * {
      padding-right: 0;
      padding-bottom: calc(${props => props.theme.sizes.edge} * 2);
    }
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
`

const Right = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${props => props.theme.medias.medium} {
    width: 100%;
  }
`

function Craft () {
  return (
    <Structure>
      <Toolbar />
      <Section title='Deploy option' description='Create a new option series'>
        <Canvas>
          <Left>
            <Sections.Checkout />
          </Left>
          <Right>
            <Sections.Utilities />
          </Right>
        </Canvas>
      </Section>
    </Structure>
  )
}

export default Craft
