import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import SpecialButton from '../../../../../common/Form/Button'
import { ContentBox, ContentSection, ContentSummary } from '../../Elements'
import {
  Incentive,
  Label,
  Input,
  Step,
  Container as FormPartial
} from '@pods-finance/components'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs } from '../../../../../../constants'

import {
  useOptionInfo,
  useOwnBalance,
  useOptionDynamics,
  useFormValidator,
  useTransactionSetup,
  useNetworkId
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 538px;
  div[data-step='actions'] {
    max-width: 100%;
    & > div:first-child {
      min-width: 226px;
    }
  }
`

const Info = styled.p``

function Unmint ({ warning, isDisabled }) {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.unmint.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const {
    option,
    collateral,
    underlying,
    strike,
    isLoading: isOptionLoading
  } = useOptionInfo()

  const { dynamics, isLoading: isLoadingDynamics } = useOptionDynamics(
    _.get(option, 'uuid')
  )
  const { elements, state, dispatch } = reducers.unmint.useReducer()

  const {
    value: balanceOptions,
    isLoading: isBalanceOptionsLoading
  } = useOwnBalance(option)

  const mintedOptions = useMemo(
    () => _.get(dynamics, 'userOptionMintedAmount.humanized'),
    [dynamics]
  )

  const isLoading = useMemo(
    () => isOptionLoading || isBalanceOptionsLoading || isLoadingDynamics,
    [isOptionLoading, isBalanceOptionsLoading, isLoadingDynamics]
  )

  const unmintableOptions = useMemo(() => {
    return BigNumber.min(balanceOptions, mintedOptions)
  }, [balanceOptions, mintedOptions])

  const { isValid: isFormValid, isAllowed: isFormAllowed } = useFormValidator({
    state,
    machine
  })

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const onPrimaryRefocus = useCallback(
    isCollateralPrimary =>
      logic.onPrimaryRefocus({
        isCollateralPrimary,
        dispatch,
        elements,
        state
      }),
    [dispatch, elements, state]
  )

  const onChangeAmount = useCallback(
    (options = null, collateral = null) =>
      logic.onChangeAmount({
        amounts: {
          options,
          collateral
        },
        dispatch,
        elements,
        option,
        unmintableOptions
      }),
    [dispatch, elements, option, unmintableOptions]
  )

  const onTransact = useCallback(() => {
    if (!isDisabled) logic.onTransact({ machine, state, option, setup })
  }, [machine, state, option, setup, isDisabled])

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        collateral,
        underlying,
        strike,
        unmintableOptions
      })
    }
  }, [
    elements,
    dispatch,
    isLoading,
    collateral,
    underlying,
    strike,
    unmintableOptions
  ])

  return (
    <ContentBox hash={[tabs.option.unmint]} isLoading={isLoading}>
      <Wrapper>
        {warning && (
          <Incentive symbol='⚠️'>
            <p>{warning}</p>
          </Incentive>
        )}
        <ContentSection
          title='Unmint option tokens to unlock collateral'
          isContained
          isDisabled={
            [machine.states.validate, machine.states.process].includes(
              machine.current.value
            ) || isDisabled
          }
        >
          <Form>
            <Step>
              <Label>Step 1: Options to unlock</Label>
              <Input.Amount
                {...state.options}
                placeholder='Enter amount'
                networkId={networkId}
                onChange={e => onChangeAmount(_.get(e, 'target.value'), null)}
                onClick={() => onPrimaryRefocus(false)}
              />

              <Step>
                <Info style={{ whiteSpace: 'nowrap' }}>Will unlock</Info>
                <Input.Amount
                  {...state.collateral}
                  placeholder='Enter amount'
                  networkId={networkId}
                  onChange={e => onChangeAmount(null, _.get(e, 'target.value'))}
                  onClick={() => onPrimaryRefocus(true)}
                />
              </Step>
            </Step>
            <ContentSummary
              index='2'
              context={tabs.option.unmint}
              transact={
                <SpecialButton.Transact
                  title='Unmint Options'
                  isDisabled={!isFormValid}
                  isAllowed={isFormAllowed}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                />
              }
            />
          </Form>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

Unmint.propTypes = {
  isDisabled: PropTypes.bool,
  warning: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

Unmint.defaultProps = {
  isDisabled: false,
  warning: null
}

export default Unmint
