import React from 'react'
import styled from 'styled-components'
import { Table as TablePartial } from '@pods-finance/components'

import { Section } from '../../../common'
import { useWatchedTable } from '../../../../hooks'

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;

  ${props => props.theme.styles.horizontalScrollContainer};

  margin: 0;
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  min-width: 1000px;
  padding: 0 ${props => props.theme.sizes.layoutEdge};

  div[data-component='body'] {
    min-height: 86px;
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

function Options () {
  const { data } = useWatchedTable()

  return (
    <Section>
      <Content>
        <TableWrapper>
          <Table id='watched' data={data} />
        </TableWrapper>
      </Content>
    </Section>
  )
}

export default Options
