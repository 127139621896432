import React, { useCallback, useMemo } from 'react'
import { macros, networks } from '@pods-finance/globals'

import IconClipboard from '@material-ui/icons/AttachmentRounded'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { TokenMultiDisplay } from '@pods-finance/components'
import _ from 'lodash'
import { beautifyAddress } from '@pods-finance/utils'
import { pages } from '../../../../../constants'
import styled from 'styled-components'
import { useClipboard } from 'use-clipboard-copy'
import { useToasts } from 'react-toast-notifications'
import { useToken } from '../../../../../hooks'

const WrapperPartial = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  outline: 1px solid ${props => props.theme.colors.border};
`

const Left = styled.div`
  height: 100%;
  margin-right: 10px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Data = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 8px;

  *[data-component='token-icons'] {
    display: none;
  }
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
  p {
    font-size: 10pt !important;
    font-weight: 600 !important;
    color: ${props => props.theme.colors.contentMedium} !important;
    margin: 0;
  }

  & > p {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    &:after {
      content: '-';
      display: inline-flex;
      margin: auto 2px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`

const Button = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  & > svg {
    color: ${props => props.theme.colors.contentMedium};
    font-size: 12pt;
  }

  &:hover,
  &:active {
    background: rgba(255, 255, 255, 0.1);
  }

  &[data-active='true'] {
    background: rgba(0, 0, 0, 0.5) !important;
  }
`

const Divider = styled.div`
  height: 42px;
  background-color: ${props => props.theme.colors.border};
  width: 1px;
  margin: 0 8px;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: 8px;
    &:last-child {
      margin: 0;
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
    margin-left: 4px;
  }
`

const Wrapper = styled(WrapperPartial)``

function Pod ({ option }) {
  const clipboard = useClipboard({ copiedTimeout: 1000 })
  const { addToast } = useToasts()

  const _tokens = useMemo(() => [option.underlying, option.strike], [option])
  const type = useMemo(
    () =>
      _.toNumber(_.get(option, 'exerciseType')) ===
      macros.EXERCISE_TYPE.european
        ? 'European'
        : 'American',
    [option]
  )

  const { value: tokens } = useToken(_tokens)

  const onCopy = useCallback(() => {
    clipboard.copy(option.address)

    addToast(
      'Address copied to clipboard. Paste in the dashboard and start watching the option.',
      {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 10000
      }
    )
  }, [option, clipboard, addToast])

  return (
    <Wrapper>
      <Left>
        <TokenMultiDisplay
          tokens={tokens.slice(0, 2)}
          isSelfPadded={false}
          isLabelIncluded={false}
        />
      </Left>
      <Data>
        <Row>
          <TokenMultiDisplay tokens={tokens.slice(0, 2)} isSelfPadded={false} />{' '}
          <p>{type}</p>
        </Row>

        <Subtitle>
          <p>
            {_.get(networks, `_data[${option.networkId}].name`)} {}
          </p>
          <p>{beautifyAddress(option.address)}</p>
        </Subtitle>
      </Data>
      <Divider />
      <Buttons>
        <Button as='div' onClick={onCopy} data-active={clipboard.copied}>
          <IconClipboard />
        </Button>
        <Button to={pages.option.builder(option.uuid)}>
          <pages.option.Icon />
        </Button>
      </Buttons>
    </Wrapper>
  )
}

Pod.propTypes = {
  data: PropTypes.shape({})
}

Pod.defaultProps = {
  option: {}
}

export default Pod
