import { useAtomicReducer } from '@pods-finance/hooks'

const initial = {
  collateral: {
    value: null,
    max: null,
    token: null,
    warning: null,
    isPrimary: true
  },
  options: {
    value: null,
    token: null,
    warning: null,
    isPrimary: false
  },
  allowance: {
    collateral: false,
    isLoading: true
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
