import light from './light'
import store from './store'
import craft from './forms/craft'
import search from './forms/search'
import watcher from './forms/watcher'

import exercise from './forms/exercise'
import mint from './forms/mint'
import unmint from './forms/unmint'
import withdraw from './forms/withdraw'

export default {
  light,
  store,
  craft,
  search,
  watcher,

  exercise,
  mint,
  unmint,
  withdraw
}
