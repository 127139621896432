import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconLock from '@material-ui/icons/LockRounded'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 990;
  background-color: ${props => props.theme.colors.white};
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 250ms;

  &[data-active='true'] {
    opacity: 1;
    pointer-events: all;
    transition: opacity 250ms;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: ${props => props.theme.sizes.edge};
  border-radius: 4px;
  cursor: pointer;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${props => props.theme.sizes.edge};
  & > svg {
    color: ${props => props.theme.colors.middle};
    font-size: 14pt;
    transition: color 250ms;
  }
`

const ConnectWarning = styled.p`
  font-size: 11pt;
  font-weight: 600;
  margin: 0;
  background: ${props => props.theme.gradients.primary};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

function SupportConnect ({ isActive, onClick }) {
  return (
    <Wrapper data-active={!isActive}>
      <Row onClick={onClick}>
        <Icon>
          <IconLock />
        </Icon>
        <ConnectWarning>Please connect your wallet</ConnectWarning>
      </Row>
    </Wrapper>
  )
}

SupportConnect.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}

SupportConnect.defaultProps = {
  isActive: false,
  onClick: _.noop
}

export default SupportConnect
