import _ from 'lodash'
import SDK from '@pods-finance/sdk'
import { networks } from '@pods-finance/globals'
import { getClient } from '../../apollo/client'

export async function updateOptions ({ options, dispatch }) {
  if (
    _.isNil(options) ||
    !options.every(
      option => _.has(option, 'uuid') || _.has(option, 'networkId')
    )
  ) {
    console.error('Static Data: options misconfigured.')
    return
  }

  if (!options.length) return

  try {
    dispatch(['_status', { isLoading: true, warning: null }], 'DEEP_UPDATE')

    if (options.length === 1) console.info('[ ---- ] Requesting option')
    else console.info('[ ---- ] Requesting options')

    const queries = networks._supported_factory.map(networkId => {
      const list = options.filter(
        option => _.get(option, 'networkId') === networkId
      )
      return async () => {
        try {
          const result = await SDK.OptionBuilder.fromAddresses({
            client: getClient(networkId),
            addresses: list.map(option => option.address),
            networkId: networkId
          })

          return [networkId, result]
        } catch (e) {
          return [networkId, null]
        }
      }
    })

    const list = await Promise.all(queries.map(q => q()))

    const flatten = list
      .map(result => result[1])
      .reduce((p, c) => (c && c.length ? [...p, ...c] : p), [])

    if (flatten && flatten.length) {
      if (flatten.length !== options.length) {
        dispatch(
          options
            .filter(o => !flatten.map(f => f.uuid).includes(o.uuid))
            .map(option => [
              option.uuid,
              {
                value: null,
                warning: 'Option not found.'
              }
            ]),

          'MULTI_DEEP_UPDATE'
        )
      }

      dispatch(
        [
          ...flatten.map(option => [
            option.uuid,
            {
              value: option
            }
          ]),
          [
            '_status',
            {
              isLoading: false,
              warning: null
            }
          ]
        ],
        'MULTI_DEEP_UPDATE'
      )
    } else {
      throw new Error(
        `${options.length === 1 ? 'Option' : 'Options'} could not be found.`
      )
    }
  } catch (error) {
    console.error('Data:', error)

    dispatch(
      [
        ...options.map(option => [
          option.uuid,
          {
            value: null
          }
        ]),
        [
          '_status',
          {
            isLoading: false,
            warning: error.message
          }
        ]
      ],
      'MULTI_DEEP_UPDATE'
    )
  }
}

export async function updateOption ({ option, dispatch }) {
  if (_.isNil(option) || !_.has(option, 'address')) {
    console.error('Data: option misconfigured.')
    return
  }

  return updateOptions({
    options: [option],
    dispatch
  })
}

/**
 *
 * @param {object} params
 * @param {ethers.providers.Provider} provider This needs to be the user's wallet provider
 * @param {number} params.networkId
 * @param {function} params.set
 * @returns
 */
export async function updateHelper ({
  networkId,
  provider: userProvider,
  set
}) {
  if (_.isNil(networkId) || _.isNil(userProvider)) {
    console.error('Data: networkId misconfigured.')
    return
  }

  try {
    const helper = await SDK.HelperBuilder.resolve({
      client: getClient(networkId),
      provider: userProvider
    })

    set(helper)
  } catch (e) {
    console.error(e)
  }
}
