import _ from 'lodash'
import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import IconForced from '@material-ui/icons/SearchRounded'
import { useParams } from 'react-router-dom'

import { Step, Label } from '@pods-finance/components'
import { useToken } from '../../../../../../hooks'
import { tabs } from '../../../../../../constants'

const Cell = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 460px;
  & > ${Cell} {
    max-width: 264px;
    &:first-child {
      padding-right: ${props => props.theme.sizes.edge};
      min-width: 200px;
    }
    &:last-child {
      flex: 1;
    }
  }

  ${props => props.theme.medias.small} {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    & > ${Cell} {
      &:first-child {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
      &:last-child {
        margin-top: ${props => props.theme.sizes.edge};
        max-width: 100%;
        width: 100%;
      }
    }
  }
`

const Toggle = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-left: 8px;
  transform: rotate(0deg);
  transition: transform 200ms;
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  transition: background-color 150ms;

  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
  }

  &[data-active='true'] {
    transform: rotate(-180deg);
    transition: transform 200ms;
  }

  &[data-forced='true'] {
    cursor: default;
    transform: rotate(0deg) !important;
    transition: transform 200ms;
  }
  &:not([data-forced='true']) {
    &:hover,
    &:active {
      background-color: ${props => props.theme.colors.contentLight};
      transition: background-color 150ms;
    }
  }
`

const Explainer = styled.div`
  width: 100%;
  max-height: 0;
  transition: max-height 400ms;
  overflow: hidden;
  & > * {
    opacity: 0;

    transition: opacity 200ms;
  }

  &[data-active='true'] {
    max-height: 500px;
    transition: max-height 400ms;
    & > * {
      opacity: 1;
      transition: opacity 200ms;
    }
  }
`

const ExplainerContent = styled.div`
  margin: 0;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 1.5);
  padding-top: calc(${props => props.theme.sizes.edge} * 1.5);
  margin-top: calc(${props => props.theme.sizes.edge} * -1.5);

  font-size: 11pt !important;
  font-weight: 500;
  color: ${props => props.theme.colors.contentMedium};
  line-height: 1.8;

  b {
    font-weight: 700;
  }

  div {
    display: inline-flex;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

const LearnMoreAnimation = keyframes`
  0%{
    opacity: 0;
    transform: translateX(-20px);
  }
  3%, 42%{
    opacity: 1;
    transform: translateX(0);
  }
  45%, 100%{
    opacity: 0;
    transform: translateX(-20px);
  }

`

const LearnMore = styled.div`
  position: relative;
  padding-left: 6px;
  animation-name: ${LearnMoreAnimation};
  animation-fill-mode: forwards;
  animation-duration: 16s;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  opacity: 0;
  transform: translateX(-20px);

  height: 24px;
  & > p {
    display: flex;
    align-items: center;
    position: absolute;
    left: -12px;
    top: 0;
    padding-right: 6px;
    padding-left: 18px;
    margin: 0;
    white-space: nowrap;
    height: 24px;
    border-radius: 2px;
    font-size: 9pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    text-align: center;
    background-color: ${props => props.theme.colors.white};
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &[data-active='true'] {
    animation-play-state: paused;
  }
`

function interpret ({ id, data, context, resolve }) {
  switch (context) {
    case tabs.option.mint:
      return 'Lock collateral and mint options that you can later distribute to other users.'
    case tabs.option.unmint:
      return "Unminting options unlocks your collateral. Note that even if your option balance is above zero, if you bought those options you won't be able to unmint them. Only the account that minted them in the first place can unlock collateral pre-exercise."
    case tabs.option.withdraw:
      return 'Withdraw unused collateral and (if exercised by option holders) an amount of underlying tokens.'
    case tabs.option.exercise:
      return 'Exercise your tokens for collateral. Swap the anticollateral (underlying asset in case of PUT options, strike asset in case of CALLS) for the collateral (opposite token).'
    default:
      return 'WAGMI!'
  }
}

function ContentSummary ({
  className,
  index,
  data,
  allow,
  transact,
  context,
  isForced
}) {
  const { get: resolve } = useToken()
  const { id } = useParams()
  const interpreted = useMemo(() => interpret({ id, data, context, resolve }), [
    id,
    data,
    context,
    resolve
  ])

  const [isActive, setIsActive] = useState(isForced)
  const toggle = useCallback(() => {
    if (isForced) return
    setIsActive(prev => !prev)
  }, [isForced, setIsActive])

  return (
    <Step className={className} isLast>
      <Label onClick={toggle}>
        Step {_.isString(index) ? index : `${index}.`} Summary and confirmation{' '}
        <Toggle data-active={isActive} data-forced={isForced}>
          {isForced ? <IconForced /> : <ArrowDown />}
        </Toggle>
        <LearnMore data-active={isActive}>
          <p>Learn More</p>
        </LearnMore>
      </Label>
      <Explainer data-active={isActive}>
        <ExplainerContent>{interpreted}</ExplainerContent>
      </Explainer>
      <Row data-step='actions'>
        {allow && <Cell>{allow}</Cell>}
        {transact && <Cell>{transact}</Cell>}
      </Row>
    </Step>
  )
}

ContentSummary.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allow: PropTypes.node,
  transact: PropTypes.node,
  data: PropTypes.shape({}),
  context: PropTypes.string,
  isForced: PropTypes.bool
}

ContentSummary.defaultProps = {
  index: 3,
  allow: null,
  transact: null,
  data: {},
  isForced: false
}

export default ContentSummary
