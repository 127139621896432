import _ from 'lodash'
import React, { useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {
  Incentive,
  Label,
  Input,
  Step,
  Container as FormPartial
} from '@pods-finance/components'
import SpecialButton from '../../../../../common/Form/Button'

// import {Countdown } from '../../../../../common'
import { ContentBox, ContentSection, ContentSummary } from '../../Elements'

import reducers from '../../../../../../reducers'
import machines from '../../../../../../machines'
import { tabs } from '../../../../../../constants'

import {
  useNetworkId,
  useTransactionSetup,
  useOptionInfo,
  useOptionDynamics
} from '../../../../../../hooks'

import logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const Form = styled(FormPartial)`
  max-width: 500px;
  &[data-disabled='true'] {
    filter: grayscale(100%);
    opacity: 0.8;
  }
`

function Withdraw ({ warning, isDisabled: _isDisabled }) {
  /**
   * --------------------------------------
   * Required data and utilities
   * --------------------------------------
   */
  const machine = machines.withdraw.useMachine()
  const setup = useTransactionSetup()
  const networkId = useNetworkId()
  const { elements, state, dispatch } = reducers.withdraw.useReducer()
  const { option, durations, isLoading: isOptionLoading } = useOptionInfo()
  const { isExercising } = useMemo(() => durations || {}, [durations])

  const { dynamics, isLoading: isLoadingDynamics } = useOptionDynamics(
    _.get(option, 'uuid')
  )

  const [withdrawBalanceUnderlying, withdrawBalanceStrike] = useMemo(
    () => [
      _.get(dynamics, 'userOptionWithdrawAmounts.0.humanized'),
      _.get(dynamics, 'userOptionWithdrawAmounts.1.humanized')
    ],
    [dynamics]
  )

  const isLoading = useMemo(() => isOptionLoading || isLoadingDynamics, [
    isOptionLoading,
    isLoadingDynamics
  ])

  const isDisabled = useMemo(
    () =>
      isExercising ||
      _isDisabled ||
      _.toArray([withdrawBalanceStrike, withdrawBalanceUnderlying]).every(
        amount => amount && amount.isZero()
      ),
    [
      isExercising,
      _isDisabled,
      withdrawBalanceStrike,
      withdrawBalanceUnderlying
    ]
  )

  /**
   * --------------------------------------
   *  Methods and state updaters
   * --------------------------------------
   */

  const onTransact = useCallback(() => {
    if (!isDisabled) logic.onTransact({ machine, state, option, setup })
  }, [machine, state, option, setup, isDisabled])

  useEffect(() => {
    if (!isLoading) {
      logic.onInitialize({
        elements,
        dispatch,
        option,
        withdrawBalanceUnderlying,
        withdrawBalanceStrike
      })
    }
  }, [
    elements,
    dispatch,
    option,
    withdrawBalanceUnderlying,
    withdrawBalanceStrike,
    isLoading
  ])

  return (
    <ContentBox hash={tabs.option.withdraw} isLoading={isLoading}>
      <Wrapper>
        {warning && (
          <Incentive symbol='⚠️'>
            <p>{warning}</p>
          </Incentive>
        )}

        {/* <ContentSection title='Your position'>
          <Countdown action={types.action.invest} />
        </ContentSection> */}
        <ContentSection
          title='Sell existing options'
          isContained
          isDisabled={
            [machine.states.validate, machine.states.process].includes(
              machine.current.value
            ) || isDisabled
          }
        >
          <Form data-disabled={isDisabled}>
            <Step>
              <Label>Step 1. Collateral to unlock</Label>
              <Input.Amount
                placeholder='Loading Amount...'
                token={state.strike.token}
                value={state.strike.value}
                networkId={networkId}
                isLoading={isLoading}
                isViewOnly
              />
              <Input.Amount
                placeholder='Loading Amount...'
                token={state.underlying.token}
                value={state.underlying.value}
                networkId={networkId}
                isLoading={isLoading}
                isViewOnly
              />
            </Step>
            <ContentSummary
              index={3}
              context={tabs.option.withdraw}
              transact={
                <SpecialButton.Transact
                  title='Withdraw'
                  isDisabled={isDisabled}
                  isLoading={[
                    machine.states.validate,
                    machine.states.process
                  ].includes(machine.current.value)}
                  onClick={onTransact}
                  warning={
                    isDisabled
                      ? 'You can only withdraw after the exercise window if you wrote options or provided liquidity.'
                      : undefined
                  }
                />
              }
            />
          </Form>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

Withdraw.propTypes = {
  isDisabled: PropTypes.bool,
  warning: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

Withdraw.defaultProps = {
  isDisabled: false,
  warning: null
}

export default Withdraw
