import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useClipboard } from 'use-clipboard-copy'
import { TokenMultiDisplay } from '@pods-finance/components'
import { useTokenByNetworkId } from '@pods-finance/hooks'
import { beautifyAddress } from '@pods-finance/utils'

import AssetContract from '@material-ui/icons/GridOnRounded'

const WrapperPartial = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  cursor: pointer;

  &:before {
    position: absolute;
    right: 10px;
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateX(100px);
    transition: transform 150ms;
  }

  &:after {
    display: none;
    position: absolute;
    right: 10px;
    content: 'Copied';
    font-size: 9pt;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    text-align: right;
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  *[data-component='token-label'] {
    display: none;
  }
  *[data-component='token-icons'] {
    margin: 0;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: calc(${props => props.theme.sizes.edge} * 1);
  padding-left: 0;
`

const Title = styled.div`
  margin-bottom: 6px;
  & > p {
    font-size: 11pt;
    font-weight: 700;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
`

const Content = styled.div`
  & > p {
    font-size: 9pt;
    font-weight: 500;
    color: ${props => props.theme.colors.content};
    margin: 0;
  }
`

const Icon = styled.div`
  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.dark};
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-copied='true'] {
    outline: 1px solid ${props => props.theme.colors.border};
    &:after {
      display: flex;
    }
  }

  &[data-copied='false'] {
    &:hover,
    &:active {
      &:before {
        transform: translateX(0);
        transition: transform 150ms;
      }
    }
  }
`

export default function Item ({
  type,
  title,
  tokens: _tokens,
  content,
  networkId
}) {
  const { value: tokens } = useTokenByNetworkId(_tokens, networkId)
  const clipboard = useClipboard({ copiedTimeout: 1000 })

  return (
    <Wrapper
      data-copied={clipboard.copied}
      onClick={() => {
        clipboard.copy(content)
      }}
    >
      <Left>
        {type === 'contract' && (
          <Icon>
            <AssetContract />
          </Icon>
        )}
        {type === 'tokens' && (
          <TokenMultiDisplay tokens={tokens} isSelfPadded />
        )}
      </Left>
      <Right>
        <Title>
          <p>{title}</p>
        </Title>
        <Content>
          <p>{beautifyAddress(content, 10)}</p>
        </Content>
      </Right>
    </Wrapper>
  )
}

Item.propTypes = {
  networkId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['contract', 'tokens']),
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  tokens: PropTypes.arrayOf(PropTypes.string)
}

Item.defaultProps = {
  type: null,
  tokens: []
}
