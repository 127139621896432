import React from 'react'
import styled from 'styled-components'
import { Network } from '../../../../common'
import { useClipboard } from 'use-clipboard-copy'
import { useToasts } from 'react-toast-notifications'
import IconAttachment from '@material-ui/icons/AttachmentRounded'

const Box = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(${props => props.theme.sizes.edge} * 1 / 2)
    calc(${props => props.theme.sizes.edge} * 1);
  outline: 1px solid ${props => props.theme.colors.border};
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &[data-copied='true'] {
    outline: 1px solid ${props => props.theme.colors.borderMedium};
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const NetworkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  flex: 1;
  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
    max-width: 150px;
    ${props => props.theme.extensions.ellipsis};
  }
`

const Icon = styled.div`
  & > svg {
    color: ${props => props.theme.colors.dark};
    font-size: 12pt;
  }
`

const Divider = styled.div`
  height: 30px;
  width: 1px;
  background-color: ${props => props.theme.colors.border};
  margin: calc(${props => props.theme.sizes.edge} * 1);
`

export default function Address ({ address, networkId }) {
  const clipboard = useClipboard({ copiedTimeout: 5000 })
  const toast = useToasts()

  return (
    <Box
      data-copied={clipboard.copied}
      onClick={() => {
        clipboard.copy(address)
        toast.addToast('Address copied to clipboard.', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      }}
    >
      <NetworkContainer>
        <Network id={networkId} />
      </NetworkContainer>
      <Divider />
      <Content>
        <p>{address}</p>
      </Content>
      <Icon>
        <IconAttachment />
      </Icon>
    </Box>
  )
}
