import _ from 'lodash'
import rollbar from './rollbar'

export const analytics = {
  init: _.noop,
  track: _.noop
}

export const crashlytics = {
  init: () => {
    rollbar.init()
  },
  log: rollbar.log,
  info: rollbar.info,
  error: rollbar.error
}

export const initialize = () => {
  try {
    crashlytics.init()
  } catch (e) {
    console.error(e)
  }
}
