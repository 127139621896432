import _ from 'lodash'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { useToasts } from 'react-toast-notifications'
import { useAtomicMachine } from '@pods-finance/hooks'
import {
  toQuantity,
  guards,
  handleTransactionReason
} from '@pods-finance/utils'

import { useModal } from '@pods-finance/contexts'
import { words } from '@pods-finance/globals'
import { modals } from '../../constants'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')

      const form = await guards.interpret(
        () =>
          guards.isFormValid({
            value: state
          }),
        addToast
      )

      if (form[0] === false) throw new Error(form[1])

      const options = await guards.interpret(
        () =>
          guards.isAmountValid({
            value: _.get(state, 'options.value'),
            max: _.get(state, 'options.max')
          }),
        addToast
      )

      if (options[0] === false) throw new Error(options[1])
    },
    [addToast]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const payload = _.get(context, 'payload') || {}
      const { option, setup, state } = payload

      const optionAmount = new BigNumber(_.get(state, 'options.value'))
      const classification = option.isPut() ? 'Put' : 'Call'

      const optionLabel = toQuantity(optionAmount.toString(), 'option')
      const modalLabel = `${optionLabel} of ${_.get(
        option,
        'underlying.symbol'
      )}:${_.get(option, 'strike.symbol')} ${classification}`

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          info: `Unminting ${modalLabel}.`
        })

        let rejected = null
        const { helper } = setup

        /**
         * Always send the SDK provided _element back to the SDK
         */

        await helper.doUnmint({
          option: option.fromSDK(),
          optionAmount,
          overrides: {
            gasLimit: true
          },
          callback: (error, transactionHash) => {
            if (error) rejected = error
            updateData({
              tx: transactionHash
            })
          }
        })

        if (!_.isNil(rejected)) throw rejected

        removeAllToasts()

        updateData({
          state: 'success',
          info: `Unlocked ${modalLabel}.`
        })

        addToast('Collateral successfully unlocked!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        console.error('Unmint', e)
        removeAllToasts()

        const reason = handleTransactionReason(_.get(e, 'code'))

        updateData({
          title: reason,
          state: 'error',
          info: words.errorTransactionFailed(
            `Attempted to unmint ${modalLabel}.`
          )
        })

        addToast(reason, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        setup.update()
        throw e
      }
      setup.update()
    },
    [addToast, removeAllToasts, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'unmint',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
