import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ExternalLink } from '@pods-finance/components'

import IconArrow from '@material-ui/icons/ArrowForwardRounded'

const Wrapper = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  max-width: 500px;
  overflow: hidden;
  height: 34px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid ${props => props.theme.colors.border};
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.8);
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 1) 0
    calc(${props => props.theme.sizes.edge} * 2);
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid ${props => props.theme.colors.border};
  & > p {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 10pt;
    font-weight: 600;
    margin: 0;
    color: ${props => props.theme.colors.middle};
    display: inherit;

    &:before {
      position: absolute;
      content: '';
      height: 8px;
      width: 8px;
      border-radius: 4px;
      background-color: ${props => props.theme.colors.middle};
      left: -18px;
    }
  }
`

const ContentPartial = styled(ExternalLink)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: ${props => props.theme.sizes.edge};
  background-color: ${props => props.theme.colors.grayBlueGhost};
  transition: background-color 150ms;
  cursor: pointer;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  & > p {
    margin: 0;
    font-size: 10pt;
    font-weight: 500;
    color: ${props => props.theme.colors.dark};
    max-width: 400px;
    ${props => props.theme.extensions.elllipsis};
  }
`

const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px 0px 16px;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  transition: background-color 150ms;
  & > svg {
    color: ${props => props.theme.colors.dark};
    font-size: 10pt;
  }
`

const Content = styled(ContentPartial)`
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.grayBlueLight};
    transition: background-color 150ms;
  }
`

function News ({ title, to }) {
  return (
    <Wrapper>
      <Label>
        <p>News</p>
      </Label>
      <Content to={to}>
        <Title>
          <p>{_.toString(title)}</p>
        </Title>
        <Action>
          <IconArrow />
        </Action>
      </Content>
    </Wrapper>
  )
}

News.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string
}

News.defaultProps = {
  title: 'Pods news',
  to: null
}

export default News
