import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button, Label, Input } from '@pods-finance/components'
import { useFormValidator, useUuid } from '../../../../hooks'
import reducers from '../../../../reducers'
import machines from '../../../../machines'
import * as logic from './logic'

const Wrapper = styled.div`
  width: 100%;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const Form = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 200px;
  grid-gap: calc(${props => props.theme.sizes.edge} * 1);
  padding: calc(${props => props.theme.sizes.edge} * 1);

  border-radius: calc(${props => props.theme.sizes.edge} * 1);
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};

  ${props => props.theme.medias.medium} {
    grid-template-columns: 1fr;
    grid-row-gap: 0;
    border-radius: 0;
  }

  ${props => props.theme.medias.small} {
    border-radius: 0;
    grid-template-columns: 1fr;
    grid-gap: calc(${props => props.theme.sizes.edge} * 0);
  }
`
const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calc(${props => props.theme.sizes.edge} * 1);
`

const Cell = styled.div`
  grid-column: span 1fr;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export default function Search () {
  const history = useHistory()
  const parts = useUuid()
  const machine = machines.search.useMachine()
  const { elements, state, dispatch } = reducers.search.useReducer()

  const onChangeAddress = useCallback(
    value => {
      logic.onChangeAddress({ value, elements, dispatch })
    },
    [elements, dispatch]
  )

  const onChangeNetwork = useCallback(
    value => {
      if (value !== _.get(state, 'network.value')) {
        logic.onChangeNetwork({ value, elements, dispatch })
      }
    },
    [elements, state, dispatch]
  )

  const onTransact = useCallback(
    () =>
      logic.onTransact({
        machine,
        state,
        elements,
        dispatch,
        history
      }),
    [machine, state, elements, dispatch, history]
  )

  const { isValid } = useFormValidator({
    state,
    machine
  })

  useEffect(() => {
    logic.initialize({ parts, elements, dispatch })
  }, [parts, elements, dispatch])

  return (
    <Wrapper>
      <Content>
        <Form>
          <Cell>
            <Label>Option</Label>
            <Input.Text
              {...state.address}
              networkId={state.network.value}
              placeholder='Enter option address'
              onChange={e => {
                onChangeAddress(_.get(e, 'target.value'), null)
              }}
            />
          </Cell>
          <Cell>
            <Label>Network</Label>
            <Input.Network
              {...state.network}
              networkId={state.network.value}
              placeholder='Mainnet'
              onChange={id => {
                onChangeNetwork(id, null)
              }}
            />
          </Cell>
          <Cell>
            <Label>Actions</Label>
            <Row>
              <Button
                title='Search'
                appearance={a => a.gradient}
                accent={a => a.primary}
                type={t => t.button}
                isDisabled={!isValid}
                isLoading={[
                  machine.states.validate,
                  machine.states.process
                ].includes(machine.current.value)}
                onClick={onTransact}
              />
            </Row>
          </Cell>
        </Form>
      </Content>
    </Wrapper>
  )
}
