import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { guards, isBalanceInsignificant } from '@pods-finance/utils'

function onInitialize ({
  elements,
  dispatch,

  underlying,
  strike,
  collateral,
  anticollateral,

  balanceExercisable
}) {
  dispatch([], 'RESET', [elements.allowance])
  dispatch([
    elements.anticollateral,
    { token: _.get(anticollateral, 'symbol') }
  ])
  dispatch([elements.collateral, { token: _.get(collateral, 'symbol') }])

  dispatch([
    elements.options,
    {
      max: !isBalanceInsignificant(balanceExercisable)
        ? balanceExercisable.toString()
        : null,
      token: [_.get(underlying, 'symbol'), _.get(strike, 'symbol')]
    }
  ])
}

function onChangeAmount ({
  amount,
  dispatch,
  elements,
  option,
  balanceExercisable
}) {
  dispatch([
    elements.options,
    {
      value: String(amount).trim(),
      warning: !_.isNilOrEmptyString(amount)
        ? guards.isAmountValid({
          value: amount,
          max: balanceExercisable
        })
        : null
    }
  ])

  if (option.isPut()) {
    dispatch([
      elements.anticollateral,
      {
        value: amount
      }
    ])

    dispatch([
      elements.collateral,
      {
        value: !_.isNilOrEmptyString(amount)
          ? new BigNumber(amount)
            .multipliedBy(option.strikePrice.humanized)
            .toString()
          : null
      }
    ])
  } else if (option.isCall()) {
    dispatch([
      elements.collateral,
      {
        value: amount
      }
    ])

    dispatch([
      elements.anticollateral,
      {
        value: !_.isNilOrEmptyString(amount)
          ? new BigNumber(amount)
            .multipliedBy(option.strikePrice.humanized)
            .toString()
          : null
      }
    ])
  }
}

function onTransact ({ machine, state, option, setup, signer }) {
  machine.send(machine.events.save, {
    payload: {
      state,
      option,
      setup,
      signer
    }
  })
}

export default {
  onInitialize,
  onChangeAmount,
  onTransact
}
