import _ from 'lodash'
import { useMemo, useCallback, useEffect } from 'react'
import { Option } from '@pods-finance/models'
import { useTokenByNetworkId } from '@pods-finance/hooks'
import { toQuantity, toNumeralPrice } from '@pods-finance/utils'
import { useDataStaticContext } from '../contexts/DataStatic'
import { useDataDynamicContext } from '../contexts/DataDynamic'
import { useUuid, useWatched } from './ui'
import { uuidify } from '../constants'
import { networks, macros } from '@pods-finance/globals'

/**
 * If an array is received, it will try to return all the matching tokens
 * @param {SDK.Token | SDK.Token[]} source
 */
export function useToken (source, _networkId) {
  const result = useTokenByNetworkId(source, _networkId)
  return result
}

export function useHelper () {
  const { helper } = useDataStaticContext()
  return helper
}

export function useOptions (isCurated = false) {
  const { options: raw, status } = useDataStaticContext()

  const options = useMemo(
    () =>
      status.isLoading
        ? []
        : raw
          .filter(item => !_.isNil(item.value))
          .filter(item => !isCurated || item.isCurated)
          .map(item => new Option(item) || {}),
    [raw, status, isCurated]
  )

  return useMemo(
    () => ({
      options,
      ...(status || {})
    }),
    [options, status]
  )
}

export function useOption () {
  const { uuid } = useUuid()
  const { options, ...status } = useOptions()

  const option = useMemo(() => {
    const item = options.find(option => option.uuid.toLowerCase() === uuid)

    if (_.isNil(item) || _.isNilOrEmptyString(uuid)) {
      return null
    }
    return item
  }, [options, uuid])

  const warning = useMemo(() => {
    if (_.get(status, 'isLoading')) return null
    if (_.isNil(option)) return 'Option unavailable'
    return _.get(option, 'warning')
  }, [option, status])

  return { ...status, option, warning }
}

/**
 * ---------------------------------
 * This hook will try to resolve an options that isn't officially curated (not included in the initial list)
 * It is important that
 *  1. This hook is called inside a route (so it can access the route parameters - option address in URL)
 *  2. This hook is called only one time per route/page (INSTANCE) - to minimize the risk of trigerring the request twice
 *
 *
 * @param {string} force Force optiona address to be used instead of route id
 */
export function useOptionResolverINSTANCE () {
  const { address, networkId, uuid } = useUuid()
  const { watched: source } = useWatched()
  const { isLoading } = useOptions()
  const { options: raw, trackOption: track } = useDataStaticContext()

  useEffect(() => {
    if (isLoading || _.isNilOrEmptyString(uuid)) {
      return
    }
    const item = raw.find(option => _.get(option, 'uuid') === uuid)
    const preparing = source.find(({ address, networkId }) => {
      const item = uuidify(address, networkId)
      return item === uuid
    })

    if (_.isNil(item) && _.isNil(preparing)) {
      track({
        option: {
          address,
          networkId,
          uuid
        },
        isCurated: false
      })
    }
  }, [raw, uuid, address, networkId, track, source, isLoading])
}

/**
 * --------------------------------------
 * Data (dynamics)
 * --------------------------------------
 */

export function useOptionsDynamics () {
  const { general, user } = useDataDynamicContext()
  const { isLoading: isLoadingGeneral } = useMemo(() => general.status, [
    general
  ])
  const { isLoading: isLoadingUser } = useMemo(() => user.status, [user])

  const isLoading = useMemo(() => isLoadingGeneral || isLoadingUser, [
    isLoadingGeneral,
    isLoadingUser
  ])

  const itemizer = useCallback(
    uuid => {
      const _general = general.elements.find(item => item.uuid === uuid)
      const _user = user.elements.find(item => item.uuid === uuid)

      const totalSupply = _.get(_general, 'value.totalSupply')
      const userOptionBalance = _.get(_user, 'value.userOptionBalance')
      const userOptionMintedAmount = _.get(
        _user,
        'value.userOptionMintedAmount'
      )
      const userOptionWithdrawAmounts = _.get(
        _user,
        'value.userOptionWithdrawAmounts'
      )

      return {
        totalSupply,
        userOptionBalance,
        userOptionWithdrawAmounts,
        userOptionMintedAmount
      }
    },
    [general, user]
  )

  return useMemo(
    () => ({ itemizer, isLoading, isLoadingGeneral, isLoadingUser }),
    [itemizer, isLoading, isLoadingGeneral, isLoadingUser]
  )
}

export function useOptionDynamics (uuid) {
  const {
    itemizer,
    isLoading,
    isLoadingGeneral,
    isLoadingUser
  } = useOptionsDynamics()
  const dynamics = useMemo(() => {
    if (_.isNilOrEmptyString(uuid) || isLoading) return {}
    return itemizer(uuid)
  }, [itemizer, isLoading, uuid])

  return useMemo(
    () => ({ dynamics, isLoading, isLoadingGeneral, isLoadingUser }),
    [dynamics, isLoading, isLoadingGeneral, isLoadingUser]
  )
}

/**
 * --------------------------------------
 * Data aggregators and formatters
 * --------------------------------------
 */

export function useOptionTokens () {
  const { option, isLoading } = useOption()
  const { get } = useToken(_.get(option, 'networkId'))

  return useMemo(() => {
    if (_.isNil(option) || isLoading || !_.isNilOrEmptyString(option.warning)) {
      return {
        underlying: null,
        strike: null,
        collateral: null,
        anticollateral: null,
        tokenA: null,
        tokenB: null
      }
    }

    return {
      underlying: get(option.underlying, option.networkId),
      strike: get(option.strike, option.networkId),
      collateral: get(option.collateral, option.networkId),
      anticollateral: get(option.anticollateral, option.networkId),
      tokenA: get(option.pool.tokenA, option.networkId),
      tokenB: get(option.pool.tokenB, option.networkId)
    }
  }, [get, option, isLoading])
}

export function useOptionInfo () {
  const helper = useHelper()
  const tokens = useOptionTokens()
  const { option, warning, isLoading } = useOption()
  const { itemizer, isLoading: isLoadingDynamics } = useOptionsDynamics()

  const dynamics = useMemo(() => {
    if (!_.isNil(option) && !isLoadingDynamics) {
      return itemizer(option.uuid)
    }

    return {}
  }, [itemizer, isLoadingDynamics, option])

  const isReady = useMemo(() => !_.isNil(option) && !isLoading, [
    option,
    isLoading
  ])
  const isRestricted = useMemo(
    () => isReady && (_.isNil(option) || !_.isNilOrEmptyString(warning)),
    [isReady, option, warning]
  )

  const isEuropean = useMemo(() => {
    if (
      !_.isNil(option) &&
      option.exerciseType === macros.EXERCISE_TYPE.american
    ) { return false }
    return true
  }, [option])

  const address = useMemo(() => (isReady ? option.address : null), [
    option,
    isReady
  ])
  const durations = useMemo(() => (isReady ? option.getDurations() : {}), [
    option,
    isReady
  ])

  const networkId = useMemo(
    () => (isReady ? option.networkId : networks.mainnet),
    [option, isReady]
  )

  const description = useMemo(() => {
    if (_.isNil(option) || !isReady || isRestricted) return ['', '']
    if (option.isPut()) {
      return {
        name: 'Put Option',
        info:
          'Put Options are contracts giving the owner the right, but not the obligation, to sell an asset at a pre-determined strike price.'
      }
    }
    return {
      name: 'Call Option',
      info:
        'Call Options are contracts giving the owner the right, but not the obligation, to buy an asset at a pre-determined strike price.'
    }
  }, [option, isReady, isRestricted])

  const localization = useMemo(() => {
    let [title, subtitle, description] = ['', '', '']
    if (!(_.isNil(option) || !isReady || isRestricted)) {
      title = isEuropean ? 'European' : 'American'
      subtitle = isEuropean ? 'After expiration' : 'Before expiration'
      description = `${
        isEuropean ? 'European' : 'American'
      } options are a type of instrument that allows the buyer to exercise their right ${
        isEuropean
          ? 'only after expiration, during the exercise window.'
          : 'any time before expiration.'
      }`
    }
    return { title, subtitle, description }
  }, [option, isReady, isRestricted, isEuropean])

  const supply = useMemo(() => {
    if (!_.isNil(dynamics)) {
      return toQuantity(
        toNumeralPrice(_.get(dynamics, 'totalSupply.humanized'), false),
        'option'
      )
    }
    return null
  }, [dynamics])

  return useMemo(
    () => ({
      ...tokens,
      address,
      option,
      helper,
      networkId,
      description,
      supply,
      durations,
      localization,
      isLoading,
      isLoadingDynamics,
      isReady,
      isRestricted,
      isEuropean
    }),
    [
      address,
      tokens,
      option,
      helper,
      networkId,
      description,
      supply,
      durations,
      localization,
      isLoading,
      isLoadingDynamics,
      isReady,
      isRestricted,
      isEuropean
    ]
  )
}

export function useUserCrafted () {
  const { crafted } = useDataDynamicContext()

  const { elements, status } = useMemo(() => crafted, [crafted])

  const { isLoading, warning } = useMemo(() => status, [status])

  const list = useMemo(() => {
    if (_.isNil(elements)) return []

    return elements.map(item => new Option({ value: item }))
  }, [elements])

  return useMemo(
    () => ({
      isLoading,
      warning,
      crafted: list
    }),
    [isLoading, warning, list]
  )
}
