import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { modals } from '../../../../constants'
import { Modal as Base } from '@pods-finance/components'
import { useWatched } from '../../../../hooks'

import IconClose from '@material-ui/icons/VisibilityOffRounded'
import { Network } from '../../../common'

const Modal = styled(Base)`
  & > div[data-component='card'] {
    max-width: 800px;
    border-radius: ${props => props.theme.sizes.edge};
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: calc(${props => props.theme.sizes.edge} * 2);
  padding-bottom: 32px;
`

const Box = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(${props => props.theme.sizes.edge} * 1 / 2)
    calc(${props => props.theme.sizes.edge} * 1);
  outline: 1px solid ${props => props.theme.colors.border};
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1 / 2);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
`

const NetworkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
`

const Address = styled.div`
  flex: 1;
  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
`

const Divider = styled.div`
  height: 30px;
  width: 1px;
  background-color: ${props => props.theme.colors.border};
  margin: calc(${props => props.theme.sizes.edge} * 1);
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
  background-color: ${props => props.theme.colors.platform};

  & > svg {
    font-size: 12pt;
    color: ${props => props.theme.colors.dark};
  }

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }
`

const Empty = styled.div`
  margin: calc(${props => props.theme.sizes.edge} * 1) 0;
  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0;
  }
`

function FullBreakdown () {
  const id = modals.manageWatched
  const { watched, removeWatched } = useWatched()

  return (
    <Modal id={id} title='Manage watched options'>
      <Content>
        {watched.length ? (
          watched.map((item, index) => (
            <Box key={`${_.get(item, 'address')}-${index}`}>
              <NetworkContainer>
                <Network id={_.get(item, 'networkId')} />
              </NetworkContainer>
              <Divider />
              <Address>
                <p>{_.get(item, 'address')}</p>
              </Address>
              <Divider />
              <Button
                onClick={() => {
                  removeWatched({
                    address: _.get(item, 'address'),
                    networkId: _.get(item, 'networkId')
                  })
                }}
              >
                <IconClose />
              </Button>
            </Box>
          ))
        ) : (
          <Empty>
            <p>You aren't watching any option yet.</p>
          </Empty>
        )}
      </Content>
    </Modal>
  )
}

export default FullBreakdown
