import _ from 'lodash'
import { useCallback } from 'react'
import dayjs from 'dayjs'
import { ethers } from 'ethers'
import utc from 'dayjs/plugin/utc'
import BigNumber from 'bignumber.js'
import { useToasts } from 'react-toast-notifications'
import SDK from '@pods-finance/sdk'
import { networks, macros } from '@pods-finance/globals'
import { useAtomicMachine } from '@pods-finance/hooks'
import { useModal } from '@pods-finance/contexts'
import { guards, handleTransactionReason } from '@pods-finance/utils'

import { modals } from '../../constants'

dayjs.extend(utc)

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()
  const { setOpen, updateData } = useModal(modals.transaction)

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      /**
       * Check fields
       */

      const state = _.get(context, 'payload.state')
      const signer = _.get(context, 'payload.signer')

      try {
        if (_.isNil(signer)) {
          throw new Error('You have to connect your wallet first.')
        }

        const factory = _.toString(_.get(state, 'factory.value'))
        if (!ethers.utils.isAddress(factory)) {
          throw new Error('Factory address is not a compatible EVM address.')
        }

        const name = _.toString(_.get(state, 'name.value'))
        if (_.isNilOrEmptyString(name) || name.length < 5) {
          throw new Error('Name has to be > 5 characters.')
        }

        const symbol = _.toString(_.get(state, 'symbol.value'))
        if (_.isNilOrEmptyString(symbol) || symbol.length < 3) {
          throw new Error('Symbol has to be > 2 characters.')
        }

        const underlying = _.toString(_.get(state, 'underlying.value'))
        if (!ethers.utils.isAddress(underlying)) {
          throw new Error('Underlying address is not a compatible EVM address.')
        }

        const strike = _.toString(_.get(state, 'strike.value'))
        if (!ethers.utils.isAddress(strike)) {
          throw new Error('Strike address is not a compatible EVM address.')
        }

        const expiration = _.toString(_.get(state, 'expiration.milliseconds'))
        if (_.isNilOrEmptyString(expiration)) {
          throw new Error('Expiration is not available.')
        } else {
          const expirationDate = dayjs(
            new BigNumber(expiration).toNumber()
          ).utc()
          if (expirationDate.isBefore(dayjs().add(2, 'hour'))) {
            throw new Error('Expiration has to be at least 2 hours from now.')
          }
        }

        const window = _.toString(_.get(state, 'window.value'))

        const localization = _.toString(_.get(state, 'localization.value.value'))

        if (localization === _.toString(macros.EXERCISE_TYPE.european)) {
          if (
            _.isNilOrEmptyString(window) ||
            new BigNumber(window).isLessThan(new BigNumber(3600))
          ) {
            throw new Error('Exercise window has to be at leat 1 hour (3600)')
          }
        } else {
          if (
            _.isNilOrEmptyString(window) ||
            !new BigNumber(window).isEqualTo(new BigNumber(0))
          ) {
            throw new Error('Exercise window has to be 0 for American Options')
          }
        }

        const price = _.toString(_.get(state, 'price.value'))
        if (_.isNilOrEmptyString(price)) {
          throw new Error('Price is not available.')
        }

        const form = await guards.interpret(
          () =>
            guards.isFormValid({
              value: state,
              soft: true
            }),
          addToast
        )

        if (form[0] === false) throw new Error(form[1])

        /**
         * Check factory
         */

        try {
          const factoryInstance = SDK.contracts.instances.optionFactory(
            signer,
            factory
          )
          await factoryInstance.configurationManager()
        } catch (e) {
          throw new Error("Couldn't ping the factory contract.")
        }

        /**
         * Check underlying token
         */

        try {
          const underlyingInstance = SDK.contracts.instances.erc20(
            signer,
            underlying
          )
          await underlyingInstance.decimals()
        } catch (e) {
          throw new Error("Couldn't ping the underlying asset contract.")
        }

        /**
         * Check strike token
         */

        try {
          const strikeInstance = SDK.contracts.instances.erc20(signer, strike)
          await strikeInstance.decimals()
        } catch (e) {
          throw new Error("Couldn't ping the strike asset contract.")
        }
      } catch (error) {
        removeAllToasts()
        addToast(_.get(error, 'message'), {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })

        throw error
      }
    },
    [addToast, removeAllToasts]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const payload = _.get(context, 'payload') || {}
      const { state, signer, setup, networkId } = payload

      try {
        setOpen(true, {
          state: 'loading',
          tx: null,
          option: null,
          networkId,
          info: 'Preparing to create the option...'
        })

        const _factory = _.toString(_.get(state, 'factory.value'))
        const _underlying = _.toString(_.get(state, 'underlying.value'))
        const _strike = _.toString(_.get(state, 'strike.value'))
        const strike = SDK.contracts.instances.erc20(signer, _strike)
        const underlying = SDK.contracts.instances.erc20(signer, _underlying)
        const factory = SDK.contracts.instances.optionFactory(signer, _factory)

        const strikeDecimals = await strike.decimals()
        const strikeSymbol = await strike.symbol()
        const underlyingSymbol = await underlying.symbol()

        const label = `${networks._data[networkId].name} ${
          _.get(state, 'classification.value.title').split(' ')[0]
        } ${underlyingSymbol}:${strikeSymbol}`

        const price = new BigNumber(
          _.toString(_.get(state, 'price.value'))
        ).times(new BigNumber(10).pow(new BigNumber(strikeDecimals)))

        const args = [
          _.toString(_.get(state, 'name.value')),
          _.toString(_.get(state, 'symbol.value')),
          _.toString(_.get(state, 'classification.value.value')),
          _.toString(_.get(state, 'localization.value.value')),
          _.toString(_.get(state, 'underlying.value')),
          _.toString(_.get(state, 'strike.value')),
          price.toFixed(0).toString(),
          new BigNumber(_.get(state, 'expiration.milliseconds'))
            .dividedBy(1000)
            .toFixed(0)
            .toString(),
          _.toString(_.get(state, 'window.value')),
          _.get(state, 'aave.value.value')
        ]

        const gasLimit = (await factory.estimateGas.createOption(...args))
          .mul(120)
          .div(100)
          .toString()

        const overrides = {
          gasLimit
        }

        updateData({
          info: `Creating the ${label} option.`
        })

        let rejected = null

        const transaction = await factory.createOption(...args, overrides)

        try {
          const receipt = await transaction.wait()
          const event = _.get(receipt, 'events.0')
          const decoder = new ethers.utils.Interface(
            SDK.contracts.abis.OptionFactoryABI
          )
          const decoded = decoder.decodeEventLog(
            'OptionCreated',
            _.get(event, 'data'),
            _.get(event, 'topics')
          )

          updateData({
            tx: receipt.transactionHash,
            option: _.get(decoded, 'option')
          })
        } catch (error) {
          console.error(error)
          rejected = error
          updateData({
            tx: error.transactionHash
          })
        }

        if (!_.isNil(rejected)) throw rejected

        updateData({
          state: 'success',
          info: `Created the ${label} option.`
        })

        addToast('Option successfully created!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        removeAllToasts()

        const reason = handleTransactionReason(_.get(e, 'code'))

        updateData({
          title: reason,
          state: 'error',
          info: 'Transaction failed or has been cancelled.'
        })

        addToast('Error', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        setup.update()
        throw e
      }
      setup.update()
    },
    [addToast, removeAllToasts, setOpen, updateData]
  )

  const machine = useAtomicMachine({
    id: 'craft',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
