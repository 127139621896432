import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@pods-finance/components'
import { Structure, Section, Toolbar } from '../../components/common'
import * as Sections from '../../components/specific/Option'
import { useOption, useOptionResolverINSTANCE } from '../../hooks'
import { useParams } from 'react-router-dom'

const Canvas = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium} * 2);
  & > * {
    padding-bottom: calc(${props => props.theme.sizes.edge} * 2);
    &:last-child {
      padding-right: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    padding: 0;
    flex-direction: column;
    padding-top: calc(${props => props.theme.sizes.edge} * 1);
    & > * {
      padding-bottom: 0;
    }
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  ${props => props.theme.medias.medium} {
  }
`

const Right = styled.div`
  width: 432px;
  display: flex;
  padding-left: calc(${props => props.theme.sizes.edge} * 1.5);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${props => props.theme.medias.medium} {
    width: 100%;
    padding-left: 0;
    padding-top: calc(${props => props.theme.sizes.edge} * 1.5);
  }
`

const Loader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: calc(${props => props.theme.sizes.edge} * 2);
`

function Option () {
  /**
   * [--------]
   *
   * Preprocessing the option instance
   */
  useOptionResolverINSTANCE()
  /**
   *[--------]
   */
  const { isLoading, warning } = useOption()
  const { uuid } = useParams()

  return (
    <Structure>
      <Toolbar />
      <Section title='Option manager'>
        <Canvas>
          <Sections.Search />
        </Canvas>
        <Canvas>
          {uuid.toLowerCase() !== 'search' &&
            (isLoading ? (
              <Loader>
                <Spinner />
              </Loader>
            ) : warning ? (
              <p>Not Found</p>
            ) : (
              <>
                <Left>
                  <Sections.Transaction />
                </Left>
                <Right>
                  <Sections.Info />
                </Right>
              </>
            ))}
        </Canvas>
      </Section>
    </Structure>
  )
}

export default Option
