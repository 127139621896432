import _ from 'lodash'
import { useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useAtomicMachine } from '@pods-finance/hooks'
import { guards } from '@pods-finance/utils'
import { ethers } from 'ethers'
import { networks } from '@pods-finance/globals'
import SDK from '@pods-finance/sdk'
import { getClient } from '../../apollo/client'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')
      const elements = _.get(context, 'payload.elements')
      const dispatch = _.get(context, 'payload.dispatch')

      try {
        const form = await guards.interpret(
          () =>
            guards.isFormValid({
              value: state
            }),
          addToast
        )

        if (form[0] === false) throw new Error(form[1])

        const address = _.toString(_.get(state, 'address.value')).toLowerCase()

        if (!ethers.utils.isAddress(address)) {
          throw new Error('Incompatible evm contract address.')
        }

        const networkId = _.toNumber(_.get(state, 'network.value'))
        if (!networks._supported_factory.includes(networkId)) {
          throw new Error('Network not supported')
        }

        const existing = await SDK.OptionBuilder.fromAddress({
          client: getClient(networkId),
          address,
          networkId
        })

        if (_.isNil(existing)) {
          throw new Error('Option unavailable on selected network.')
        }
      } catch (error) {
        removeAllToasts()
        addToast(_.get(error, 'message'), {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })

        dispatch([
          elements.address,
          {
            warning: _.get(error, 'message')
          }
        ])

        throw error
      }
    },
    [addToast, removeAllToasts]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const payload = _.get(context, 'payload') || {}
      const { state, callback } = payload

      const address = _.toString(_.get(state, 'address.value'))
      const networkId = _.toNumber(_.get(state, 'network.value'))

      try {
        if (_.isFunction(callback)) {
          callback(address, networkId)
        }
      } catch (e) {
        removeAllToasts()

        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts]
  )

  const machine = useAtomicMachine({
    id: 'search',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
