import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${props => props.theme.sizes.edge};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  /* background-color: ${props => props.theme.colors.platform}; */
  border: 1px solid ${props => props.theme.colors.border};
  padding: calc(${props => props.theme.sizes.edge} * 1);
  margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
  min-height: 40px;
`

export const CellWrapper = styled.div`
  grid-column: span ${props => props.size || 1};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${props => props.theme.medias.medium} {
    grid-column: span 2;
  }
`

export function Cell ({ children, size }) {
  return <CellWrapper size={size}>{children}</CellWrapper>
}

export default function Box ({ children }) {
  return <Wrapper>{children}</Wrapper>
}
