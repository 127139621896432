import _ from 'lodash'
import { useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useAtomicMachine } from '@pods-finance/hooks'
import { guards } from '@pods-finance/utils'
import { ethers } from 'ethers'
import { networks } from '@pods-finance/globals'

export function useMachine () {
  const { addToast, removeAllToasts } = useToasts()

  const onPrepare = useCallback(async ({ context }) => {
    const state = _.get(context, 'payload.state')

    const form = await guards.booleanize(() =>
      guards.isFormValid({ value: state, soft: true })
    )

    if (!form) return false

    return true
  }, [])

  const onValidate = useCallback(
    async ({ context }) => {
      const state = _.get(context, 'payload.state')
      const elements = _.get(context, 'payload.elements')
      const dispatch = _.get(context, 'payload.dispatch')
      const watched = _.get(context, 'payload.watched')

      try {
        const form = await guards.interpret(
          () =>
            guards.isFormValid({
              value: state
            }),
          addToast
        )

        if (form[0] === false) throw new Error(form[1])

        const address = _.toString(_.get(state, 'address.value')).toLowerCase()
        if (!ethers.utils.isAddress(address)) {
          throw new Error('Incompatible evm contract address.')
        }

        const networkId = _.toNumber(_.get(state, 'network.value'))
        if (!networks._supported_factory.includes(networkId)) {
          throw new Error('Network not supported')
        }

        if (
          watched.find(
            item =>
              _.get(item, 'address') === address &&
              _.get(item, 'networkId') === networkId
          )
        ) {
          throw new Error('You are already watching this option.')
        }
      } catch (error) {
        removeAllToasts()
        addToast(_.get(error, 'message'), {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })

        dispatch([
          elements.address,
          {
            warning: _.get(error, 'message')
          }
        ])

        throw error
      }
    },
    [addToast, removeAllToasts]
  )

  const onProcess = useCallback(
    async ({ context }) => {
      const payload = _.get(context, 'payload') || {}
      const { addWatched, state } = payload

      const address = _.toString(_.get(state, 'address.value')).toLowerCase()
      const networkId = _.toNumber(_.get(state, 'network.value'))

      try {
        addWatched({
          address,
          networkId
        })

        addToast('Address successfully watched!', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
      } catch (e) {
        removeAllToasts()

        addToast('Error', {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 5000
        })
        throw e
      }
    },
    [addToast, removeAllToasts]
  )

  const machine = useAtomicMachine({
    id: 'watcher',
    onPrepare,
    onValidate,
    onProcess
  })

  return machine
}

export default {
  useMachine
}
