import { useAtomicReducer } from '@pods-finance/hooks'

export const initial = {
  store: {
    value: []
  },
  _status: {
    isLoading: true,
    isFinished: false,
    warning: null
  }
}

function useReducer (_initial) {
  return useAtomicReducer(_initial || initial)
}

export default {
  useReducer
}
