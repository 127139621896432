import { networks, tokens } from '@pods-finance/globals'

export default {
  [networks.mainnet]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0x43ff98eb7ec681a7dbf7e2b2c3589e79d5ce11e3'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.ETH],
      title: 'ETH (WETH)',
      content: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.DAI],
      title: 'DAI',
      content: '0x6b175474e89094c44da98b954eedeac495271d0f'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.USDC],
      title: 'USDC',
      content: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.AUSDC],
      title: 'aUSDC',
      content: '0x9bA00D6856a4eDF4665BcA2C2309936572473B7E'
    }
  ],
  [networks.kovan]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0xfb8d79ab64dee62560b6a852a574fbbcb7eda84c'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.ETH],
      title: 'ETH (WETH)',
      content: '0x824b1e309c4eb33501fb49f5de9cb7481686a799'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.DAI],
      title: 'Faucet DAI',
      content: '0xff795577d9ac8bd7d90ee22b6c1703490b6512fd'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.LINK],
      title: 'Faucet LINK',
      content: '0xba74882beee5482ebba7475a0c5a51589d4ed5de'
    }
  ],
  [networks.matic]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0xf0cdada8a7383a1d1ce394e18fef9c13feda97e8'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.MATIC],
      title: 'MATIC (WMATIC)',
      content: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.ETH],
      title: 'WETH',
      content: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.DAI],
      title: 'DAI',
      content: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.USDC],
      title: 'USDC',
      content: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
    }
  ],
  [networks.arbitrum]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0xabfce3177d13a6653c6777323ae20bab86a858c0'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.ETH],
      title: 'ETH (WETH)',
      content: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.DAI],
      title: 'DAI',
      content: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.USDC],
      title: 'USDC',
      content: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8'
    }
  ],
  [networks.bsc]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0xC0977818B77F77949fad2B78B04d7E229Dc3e921'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.WBNB],
      title: 'WBNB',
      content: networks._data[networks.bsc].token.wrapped[0]
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.BUSD],
      title: 'BUSD',
      content: '0xe9e7cea3dedca5984780bafc599bd69add087d56'
    }
  ],
  [networks.fantom]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0x8Bc19f4DCe1d6dbb0E6f2Df7cb1038EE4AA1c891'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.WFTM],
      title: 'WFTM',
      content: networks._data[networks.fantom].token.wrapped[0]
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.USDC],
      title: 'USDC',
      content: '0x04068da6c83afcfa0e13ba15a6696662335d5b75'
    }
  ],
  [networks.avalanche]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0x6575e244850D82f9c263838ECd64E2356C9D4Ae3'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.WAVAX],
      title: 'WAVAX',
      content: networks._data[networks.avalanche].token.wrapped[0]
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.USDC],
      title: 'USDC',
      content: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e'
    }
  ],
  [networks.optimism]: [
    {
      id: 'factory',
      type: 'contract',
      title: 'Option Factory (default)',
      content: '0xEFAFa749dc0eF157ff8931A4aD73eF8A607Cb80a'
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.ETH],
      title: 'ETH (WETH)',
      content: networks._data[networks.optimism].token.wrapped[0]
    },
    {
      type: 'tokens',
      tokens: [tokens.keys.USDC],
      title: 'USDC',
      content: '0x7f5c764cbc14f9669b88837ca1490cca17c31607'
    }
  ]
}
