import React from 'react'
import styled from 'styled-components'
import { Emoji } from '@pods-finance/components'
import ContentBox from '../ContentBox'
import ContentSection from '../ContentSection'

const Wrapper = styled.div`
  width: 100%;
  padding-top: calc(${props => props.theme.sizes.edge} * 1);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 3 / 2);
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Info = styled.p`
  font-size: ${props => props.theme.sizes.text};
  color: ${props => props.theme.colors.dark};
  margin: 0;
  line-height: 1.5;
  font-weight: 500;

  span {
    font-size: 16pt;
  }
`

function Missing () {
  return (
    <ContentBox force isLoading={false}>
      <Wrapper>
        <ContentSection title='Option Missing'>
          <Info>
            We cannot detect this option in our list. It looks like it's gone
            missing <Emoji symbol='🛸' label='UFO' />
          </Info>
        </ContentSection>
      </Wrapper>
    </ContentBox>
  )
}

export default Missing
