import { links, routes } from '@pods-finance/globals'
export const { pages, tabs } = routes.factory

export const modals = {
  transaction: 'transaction',
  manageWatched: 'manageWatched'
}

export const prefs = {
  test: 'test',
  watching: 'watching',
  transaction: 'transaction'
}

export const features = {
  calls: ![false, 'false'].includes(process.env.REACT_APP_FEATURE_CALLS_ON),
  ipfs: [true, 'true'].includes(process.env.REACT_APP_FEATURE_ROUTE_IPFS)
}

export function uuidify (address, networkId) {
  return `${address}-${networkId}`
}

export const news = [
  {
    title: 'Tutorial: How to use Airdrip? (coming soon)',
    to: links.twitter.primary.link
  }
]

export { default as utils } from './utils'
