import React from 'react'
import styled from 'styled-components'
import IconClose from '@material-ui/icons/CloseRounded'
import { Link } from 'react-router-dom'
import MenuItem from './MenuItem'
import { pages } from '../../../constants'
import { useUI } from '../../../hooks'

import AssetLogo from '../../../assets/logo/logo_white.svg'

const WrapperPartial = styled.div`
  height: 100%;
  width: auto;
  padding: ${props => props.theme.sizes.edge};
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid ${props => props.theme.colors.border};
    height: 100px;
  }
  ${props => props.theme.medias.medium} {
    &:before {
      display: none;
    }
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 1 / 2);
  padding-bottom: 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: calc(${props => props.theme.sizes.edge} * 2.5);
`

const Logo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 54px;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  background: ${props => props.theme.gradients.primary};
  overflow: hidden;
  & > img {
    height: auto;
    width: 32px;
    object-fit: contain;
  }
`

const MainHeader = styled.div`
  display: none;
`

const MainHeaderClose = styled.div``
const Backdrop = styled.div``

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: auto;
  flex: 1;
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: auto;
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 1);
    &:last-child {
      margin: 0;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  ${props => props.theme.medias.medium} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 0 !important;
    z-index: ${props => props.theme.sizes.sideElevation};
    pointer-events: none;
    & > ${Inner} {
      padding: 0 !important;
      ${Header} {
        display: none;
      }

      ${Main} {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        z-index: ${props => props.theme.sizes.sideMediumTopElevation};

        & > ${Backdrop} {
          position: absolute;
          z-index: 50;
          width: 100vw;
          height: 100vh;
          left: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.8);
          opacity: 0;
          transition: opacity 250ms;
        }

        & > ${MainContent} {
          position: relative;
          z-index: 100;
          width: 80%;
          min-height: 100vh;
          overflow-y: auto;
          margin: 0;
          background-color: ${props => props.theme.colors.white};
          transform: translateX(-100vw);
          transition: transform 250ms;

          & > ${MainHeader} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: ${props => props.theme.sizes.navHeight};
            width: 100%;
            padding-left: calc(${props => props.theme.sizes.edge} * 3 / 2);
            padding-right: calc(${props => props.theme.sizes.edge} * 1 / 2);
            border-bottom: 1px solid ${props => props.theme.colors.border};

            & > p {
              font-size: 14pt;
              font-weight: 700;
              color: ${props => props.theme.colors.dark};
            }

            & > ${MainHeaderClose} {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 calc(${props => props.theme.sizes.edge} * 1);
              height: 100%;
              cursor: pointer;
              & > svg {
                font-size: 14pt;
                font-weight: 600;
                color: ${props => props.theme.colors.contentMedium};
              }
            }
          }

          & > ${Menu} {
            padding: calc(${props => props.theme.sizes.edge} * 1)
              calc(${props => props.theme.sizes.edge} * 1 / 2);
          }
        }
      }
    }

    &[data-drawer='true'] {
      pointer-events: all;

      ${Main} {
        & > ${Backdrop} {
          opacity: 1;
          transition: opacity 250ms;
        }
        & > ${MainContent} {
          transform: translateX(0);
          transition: transform 250ms;
        }
      }
    }
  }
`

const items = [pages.dashboard, pages.craft, pages.option, pages.profile]

function Side () {
  const { drawer, closeDrawer } = useUI()

  return (
    <Wrapper data-drawer={drawer}>
      <Inner>
        <Header>
          <Link to={pages.dashboard.route}>
            <Logo>
              <img src={AssetLogo} alt='Logo' />
            </Logo>
          </Link>
        </Header>
        <Main>
          <MainContent>
            <MainHeader>
              <p>Actions</p>
              <MainHeaderClose onClick={closeDrawer}>
                <IconClose />
              </MainHeaderClose>
            </MainHeader>
            <Menu>
              {items.map(item => (
                <MenuItem key={item.title} {...item} />
              ))}
            </Menu>
          </MainContent>
          <Backdrop onClick={closeDrawer} />
        </Main>
      </Inner>
    </Wrapper>
  )
}

export default Side
