import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { networks } from '@pods-finance/globals'
import { Network } from '../../../common'
import { utils } from '../../../../constants'
import { useNetworkId } from '../../../../hooks'

import Item from './Item'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: calc(${props => props.theme.sizes.edge} * 1.5);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid ${props => props.theme.colors.border};
`

const Title = styled.div`
  margin-bottom: ${props => props.theme.sizes.edge};
  & > p {
    margin: 0;
    font-size: 16pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
  }
`

const Networks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 8px;
  user-select: none !important;
  cursor: default !important;
`

const Divider = styled.div`
  background-color: ${props => props.theme.colors.border};
  margin: ${props => props.theme.sizes.edge} 0;
  width: 100%;
  height: 1px;
`

const Items = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calc(${props => props.theme.sizes.edge} * 1 / 2);
`

export default function Utilities () {
  const networkId = useNetworkId()

  return (
    <Wrapper>
      <Title>
        <p>Utilities</p>
      </Title>
      <Networks>
        {networks._supported_factory.map(id => (
          <Network key={id} id={id} isActive={networkId === id} />
        ))}
      </Networks>
      <Divider />
      <Items>
        {(_.get(utils, networkId) || []).map(data => (
          <Item key={_.get(data, 'title')} networkId={networkId} {...data} />
        ))}
      </Items>
    </Wrapper>
  )
}
