import React from 'react'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import { Table as TablePartial } from '@pods-finance/components'
import { useUserActivityTable } from '../../../../hooks'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  ${props => props.theme.styles.horizontalScrollContainer};
`

const TableWrapper = styled.div`
  width: 100%;
`

const Table = styled(TablePartial)`
  min-width: 900px;
  padding: 0;

  div[data-component='body'] {
    min-height: 86px;
  }

  div[data-component='row'] {
    cursor: default;
    ${props =>
      props.theme.isDark &&
      css`
        &:hover,
        &:active {
          background-color: ${props =>
            lighten(0.05, props.theme.colors.platform)};
        }
      `}
  }

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.layoutEdgeMedium});
  }
`

export default function Activity () {
  const { data } = useUserActivityTable()

  return (
    <Wrapper>
      <TableWrapper>
        <Table id='activity' data={data} />
      </TableWrapper>
    </Wrapper>
  )
}
