import React from 'react'
import styled from 'styled-components'
import { Structure, Toolbar } from '../../components/common'
import * as Sections from '../../components/specific/Dashboard'

const Canvas = styled.div`
  width: 100%;
  padding-bottom: calc(${props => props.theme.sizes.edge} * 6);
  & > * {
    margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => props.theme.medias.medium} {
    & > * {
      margin-bottom: calc(${props => props.theme.sizes.edge} * 2);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

function Dashboard () {
  return (
    <Structure>
      <Toolbar />
      <Canvas>
        <Sections.Overview />
        <Sections.Watcher />
        <Sections.Options />
      </Canvas>
    </Structure>
  )
}

export default Dashboard
