import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import IconMint from '@material-ui/icons/ScatterPlotRounded'
import IconUnmint from '@material-ui/icons/RotateLeftRounded'
import IconExercise from '@material-ui/icons/FlashOnRounded'
import IconWithdraw from '@material-ui/icons/GetAppRounded'
import * as Tabs from '../Tabs'

import { tabs } from '../../../../../constants'
import { useAccount, useWalletModal, useOptionInfo } from '../../../../../hooks'

import {
  TabBar,
  TabItem,
  SupportLoader,
  SupportNetwork,
  SupportConnect
} from '../Elements'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: ${props => props.theme.sizes.edge};
  border: 1px solid ${props => props.theme.colors.border};
  background-color: ${props => props.theme.colors.white};
  padding-bottom: ${props => props.theme.sizes.edge};
  padding-top: calc(${props => props.theme.sizes.edge} * 1 / 2);

  ${props => props.theme.medias.medium} {
    padding-bottom: 0;
    padding-top: 2px;
    border-radius: 0;
  }
`

const Content = styled.div`
  width: 100%;
  min-height: 200px;
`

function Transaction () {
  const {
    durations,
    option,
    isRestricted,
    isEuropean,
    isLoading
  } = useOptionInfo()
  const { isConnected, networkId } = useAccount()
  const { connect } = useWalletModal()

  const isReady = useMemo(() => {
    if (isLoading) return false
    if (_.isNil(option)) return false
    if (_.isNil(durations)) return false
    const { isExpired, isExercising } = durations
    if (_.isNil(isExpired) || _.isNil(isExercising)) return false

    return true
  }, [durations, option, isLoading])

  const isWrongNetwork = useMemo(
    () =>
      isReady &&
      option &&
      parseInt(networkId, 10) !== parseInt(_.get(option, 'networkId')),
    [isReady, option, networkId]
  )

  const conditions = useMemo(() => {
    const { isExpired, isExercising } = durations

    const isMintDisabled = isReady && (isExercising || isExpired)
    const isUnmintDisabled = isReady && (isExercising || isExpired)
    const isWithdrawDisabled = isReady && !isExpired
    const isExerciseDisabled = isReady && isEuropean && !isExercising

    const mintWarning = isMintDisabled
      ? 'Minting is unavailable when the option is exercising or has expired.'
      : null
    const unmintWarning = isUnmintDisabled
      ? 'Unminting is unavailable when the option is exercising or has expired.'
      : null

    const withdrawWarning = isWithdrawDisabled
      ? 'Withdrawing will be made available after expiration.'
      : null

    const exerciseWarning = isExerciseDisabled
      ? 'Exercising is available only during the exercise window.'
      : null

    return {
      isMintDisabled,
      isUnmintDisabled,
      isWithdrawDisabled,
      isExerciseDisabled,
      mintWarning,
      unmintWarning,
      withdrawWarning,
      exerciseWarning
    }
  }, [durations, isReady, isEuropean])

  return (
    <Wrapper>
      <TabBar
        fallback={tabs.option.mint}
        supported={Object.values(tabs.option)}
        locked={[]}
        isLoading={!isReady}
      >
        <TabItem
          Icon={IconMint}
          title='Mint'
          hash={tabs.option.mint}
          isLocked={conditions.isMintDisabled}
        />
        <TabItem
          Icon={IconUnmint}
          title='Unmint'
          hash={tabs.option.unmint}
          isLocked={conditions.isUnmintDisabled}
        />
        <TabItem
          Icon={IconWithdraw}
          title='Withdraw'
          hash={tabs.option.withdraw}
          isLocked={conditions.isWithdrawDisabled}
        />
        <TabItem
          Icon={IconExercise}
          title='Exercise'
          hash={tabs.option.exercise}
          isLocked={conditions.isExerciseDisabled}
        />
      </TabBar>
      <Content>
        {isWrongNetwork ? (
          <SupportNetwork networkId={_.get(option, 'networkId')} />
        ) : (
          <>
            <Tabs.Mint
              isDisabled={conditions.isMintDisabled}
              warning={conditions.mintWarning}
            />
            <Tabs.Unmint
              isDisabled={conditions.isUnmintDisabled}
              warning={conditions.unmintWarning}
            />
            <Tabs.Withdraw
              isDisabled={conditions.isWithdrawDisabled}
              warning={conditions.withdrawWarning}
            />
            <Tabs.Exercise
              isDisabled={conditions.isExerciseDisabled}
              warning={conditions.exerciseWarning}
            />
          </>
        )}
      </Content>
      <SupportLoader isActive={!isReady && !isRestricted} />
      <SupportConnect isActive={isConnected} onClick={connect} />
    </Wrapper>
  )
}

export default Transaction
