import _ from 'lodash'
import React, { useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'

import { Button, Label, Input } from '@pods-finance/components'
import {
  useAccount,
  useTransactionSetup,
  useWeb3Utilities
} from '../../../../hooks'
import reducers from '../../../../reducers'
import machines from '../../../../machines'

import { Form } from '../../../common'
import * as logic from './logic'

const Wrapper = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: calc(${props => props.theme.sizes.edge} * 1.5);
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  outline: 1px solid ${props => props.theme.colors.border};
  ${props => props.theme.medias.medium} {
    padding: calc(${props => props.theme.sizes.edge} * 1)
      calc(${props => props.theme.sizes.edge} * 1);
  }
`

const Warner = styled(Button)`
  min-height: 50.4px;
  p {
    font-size: 9pt !important;
  }
`

export default function Checkout () {
  const setup = useTransactionSetup()
  const { networkId, isConnected } = useAccount()
  const machine = machines.craft.useMachine()
  const { signer } = useWeb3Utilities()
  const { elements, state, dispatch } = reducers.craft.useReducer()

  useEffect(() => {
    logic.initialize({
      networkId,
      elements,
      dispatch
    })
  }, [networkId, elements, dispatch])

  const onChangeSimpleInput = useCallback(
    (key, value) => {
      logic.onChangeSimpleInput({ key, value, dispatch })
    },
    [dispatch]
  )

  const onChangeExpirationInput = useCallback(
    value => {
      logic.onChangeExpirationInput({ value, elements, dispatch })
    },
    [elements, dispatch]
  )

  const onTransact = useCallback(
    () =>
      logic.onTransact({
        machine,
        state,
        signer,
        setup,
        networkId,
        elements,
        dispatch
      }),
    [machine, state, signer, networkId, setup, elements, dispatch]
  )

  const error = useMemo(() => _.get(machine, 'current.context.error'), [
    machine
  ])

  return (
    <Wrapper>
      <Label>Step 1. Initial Setup</Label>
      <Form.Box>
        <Form.Cell size={2}>
          <Label>Factory address</Label>
          <Input.Text
            {...state.factory}
            placeholder='0x...'
            networkId={networkId}
            onChange={e =>
              onChangeSimpleInput(elements.factory, _.get(e, 'target.value'))}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Option classification</Label>
          <Input.Select
            {...state.classification}
            networkId={networkId}
            onChange={item =>
              onChangeSimpleInput(elements.classification, item)}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Option exercise type</Label>
          <Input.Select
            {...state.localization}
            networkId={networkId}
            onChange={item => onChangeSimpleInput(elements.localization, item)}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Name</Label>
          <Input.Text
            {...state.name}
            placeholder='Pod Put ETH:USDC 420 ...'
            networkId={networkId}
            onChange={e =>
              onChangeSimpleInput(elements.name, _.get(e, 'target.value'))}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Symbol</Label>
          <Input.Text
            {...state.symbol}
            placeholder='pETHUSDC'
            networkId={networkId}
            onChange={e =>
              onChangeSimpleInput(elements.symbol, _.get(e, 'target.value'))}
          />
        </Form.Cell>
      </Form.Box>
      <Label>Step 2. Instrument</Label>
      <Form.Box>
        <Form.Cell>
          <Label>Underlying asset</Label>
          <Input.Text
            {...state.underlying}
            placeholder='0x...'
            networkId={networkId}
            isTokenLabelIncluded={false}
            onChange={e =>
              onChangeSimpleInput(elements.underlying, _.get(e, 'target.value'))}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Strike asset</Label>
          <Input.Text
            {...state.strike}
            placeholder='0x...'
            networkId={networkId}
            isTokenLabelIncluded={false}
            onChange={e =>
              onChangeSimpleInput(elements.strike, _.get(e, 'target.value'))}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Strike price</Label>
          <Input.Amount
            {...state.price}
            placeholder='e.g. 420 (USDC)'
            networkId={networkId}
            onChange={e =>
              onChangeSimpleInput(elements.price, _.get(e, 'target.value'))}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Expiration Date (includes window)</Label>
          <Input.Dater
            {...state.expiration}
            networkId={networkId}
            placeholder='Any | Pick date ...'
            onChange={e => onChangeExpirationInput(_.get(e, 'target.value'))}
            isDatetime
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Exercise Window (seconds)</Label>
          <Input.Amount
            {...state.window}
            networkId={networkId}
            placeholder='e.g. 86400 (24h)'
            onChange={e =>
              onChangeSimpleInput(elements.window, _.get(e, 'target.value'))}
          />
        </Form.Cell>
        <Form.Cell>
          <Label>Is the strike asset an aToken?</Label>
          <Input.Select
            {...state.aave}
            networkId={networkId}
            onChange={item => onChangeSimpleInput(elements.aave, item)}
          />
        </Form.Cell>
      </Form.Box>
      <Label>Step 3. Craft</Label>
      <Form.Box>
        <Form.Cell>
          <Form.Button.Transact
            title='Validate &amp; Create Option'
            isAllowed
            isDisabled={!isConnected}
            isLoading={[
              machine.states.validate,
              machine.states.process
            ].includes(machine.current.value)}
            onClick={onTransact}
          />
        </Form.Cell>
        {error && (
          <Form.Cell>
            <Warner
              title={error}
              type={t => t.button}
              accent={a => a.whiteRed}
              appearance={a => a.solid}
              isDisabledSoft
            />
          </Form.Cell>
        )}
      </Form.Box>
    </Wrapper>
  )
}
