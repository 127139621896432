import { ethers } from 'ethers'
import { pages, uuidify } from '../../../../constants'

export function onChangeAddress ({ value: _value, elements, dispatch }) {
  const value = String(_value).trim()
  let warning = null

  if (value.length && !ethers.utils.isAddress(value)) {
    warning = 'Incompatible evm contract address.'
  }

  dispatch([
    elements.address,
    {
      value,
      warning
    }
  ])
}

export function onChangeNetwork ({ value, elements, dispatch }) {
  dispatch([
    elements.network,
    {
      value,
      warning: null
    }
  ])

  dispatch([
    elements.address,
    {
      warning: null
    }
  ])
}

export function onTransact ({ machine, state, elements, dispatch, history }) {
  const callback = (address, networkId) => {
    const uuid = uuidify(address, networkId)
    history.replace(pages.option.builder(uuid))
  }

  machine.send(machine.events.save, {
    payload: {
      state,
      elements,
      dispatch,
      callback
    }
  })
}

export function initialize ({ parts, elements, dispatch }) {
  try {
    if (!parts) return
    const { address, networkId } = parts

    if (address && networkId) {
      dispatch(
        [
          [elements.address, { value: address.toLowerCase() }],
          [elements.network, { value: networkId }]
        ],
        'MULTI_UPDATE'
      )
    }
  } catch (e) {
    console.error('Search', e)
  }
}
