import _ from 'lodash'
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import SDK from '@pods-finance/sdk'
import reducers from '../../reducers'
import { useNetworkId, useWatched, useWeb3Utilities } from '../../hooks'

import * as helpers from './helpers'

const initial = {
  uuid: null,
  value: null,
  warning: null,
  isCurated: true
}

export const DataStaticContext = createContext(null)

export default function Provider ({ children }) {
  const value = useData()

  return (
    <DataStaticContext.Provider value={value}>
      {children}
    </DataStaticContext.Provider>
  )
}

export function useDataStaticContext () {
  return useContext(DataStaticContext)
}

function useData () {
  const networkId = useNetworkId()
  const { provider, signer } = useWeb3Utilities()
  const { state, dispatch } = reducers.light.useReducer()
  const { watched } = useWatched()
  const [helper, setHelper] = useState(null)

  const updateOption = useCallback(
    ({ option }) => {
      helpers.updateOption({ option, dispatch })
    },
    [dispatch]
  )

  const updateOptions = useCallback(
    ({ options }) => {
      helpers.updateOptions({ options, dispatch })
    },
    [dispatch]
  )

  const trackOption = useCallback(
    ({ option, reset = false, isCurated = false }) => {
      if (reset) dispatch([], 'RESET')
      if (_.isNil(option)) return

      const shape = _.cloneDeep(initial)
      shape.uuid = option.uuid
      shape.value = option
      shape.isCurated = isCurated
      dispatch([option.uuid, shape], 'SET')

      updateOption({ option })
    },
    [dispatch, updateOption]
  )

  const trackOptions = useCallback(
    ({ options, reset = false, isCurated = true }) => {
      if (reset) dispatch([], 'RESET')
      if (_.isNil(options) || !options.length) return

      const shape = option => {
        const item = _.cloneDeep(initial)
        item.uuid = option.uuid
        item.value = option
        item.isCurated = isCurated
        return item
      }

      dispatch(
        options.map(o => {
          return [o.uuid, shape(o)]
        }),
        'MULTI_SET'
      )

      updateOptions({ options })
    },
    [dispatch, updateOptions]
  )

  const trackHelper = useCallback(() => {
    if (_.isNil(networkId) || _.isNil(provider)) return

    helpers.updateHelper({ networkId, provider, set: setHelper })
  }, [networkId, provider])

  const options = useMemo(
    () =>
      Object.keys(state || {})
        .filter(key => key !== '_status')
        .map(key => state[key]),
    [state]
  )

  const status = useMemo(() => _.get(state, '_status'), [state])

  useEffect(() => {
    /** Additional networkId check because an old apollo instance may be paired with a new provider on-network-change. */
    if (watched && watched.length) {
      const source = watched.map(
        ({ address, networkId }) =>
          new SDK.Option({
            address,
            networkId
          })
      )
      trackOptions({ options: source, reset: true })
    } else dispatch(['_status', { isLoading: false }])
  }, [watched, trackOptions, dispatch])

  useEffect(() => {
    if (networkId && signer) trackHelper()
  }, [networkId, signer, trackHelper])

  return useMemo(
    () => ({
      options,
      status,
      helper,
      trackOption,
      trackOptions,
      updateOption,
      updateOptions
    }),
    [
      options,
      status,
      helper,
      trackOption,
      trackOptions,
      updateOption,
      updateOptions
    ]
  )
}
