import _ from 'lodash'
import { useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { ethers } from 'ethers'
import { networks } from '@pods-finance/globals'
import { prefs, news } from '../constants'
import { useUIContext } from '../contexts/UI'

export function useVersion () {
  const { versioning } = useUIContext()
  return versioning
}
export function usePreferences () {
  const { preferences } = useUIContext()
  const { value, update } = useMemo(() => preferences, [preferences])
  return {
    value,
    update
  }
}

export function useUI () {
  const { controls } = useUIContext()
  return controls
}

export function useWatched () {
  const { value: preferences, update } = usePreferences()
  const watched = useMemo(() => {
    const sanitized = []
    const preference = _.get(preferences, prefs.watching)
    try {
      if (!_.isArray(preference)) throw new Error('Misconfigured watch list.')
      preference.forEach(set => {
        if (_.has(set, 'address') && _.has(set, 'networkId')) {
          const address = _.toString(_.get(set, 'address'))
          const networkId = _.toNumber(_.get(set, 'networkId'))
          if (
            ethers.utils.isAddress(address) &&
            networks._supported_factory.includes(networkId)
          ) {
            sanitized.push({ address, networkId })
          }
        }
      })
    } catch (error) {}
    return sanitized
  }, [preferences])

  const addWatched = useCallback(
    ({ address, networkId }) => {
      if (
        ethers.utils.isAddress(address) &&
        networks._supported_factory.includes(networkId)
      ) {
        update({
          [prefs.watching]: [
            ...watched,
            { address: address.toLowerCase(), networkId }
          ]
        })
      }
    },
    [watched, update]
  )

  const removeWatched = useCallback(
    ({ address: _address, networkId }) => {
      if (
        ethers.utils.isAddress(_address) &&
        networks._supported_factory.includes(networkId)
      ) {
        const address = _address.toLowerCase()
        update({
          [prefs.watching]: watched.filter(
            item =>
              !(
                _.get(item, 'address').toLowerCase() === address &&
                _.get(item, 'networkId') === networkId
              )
          )
        })
      }
    },
    [watched, update]
  )

  return useMemo(
    () => ({
      watched,
      addWatched,
      removeWatched
    }),
    [watched, addWatched, removeWatched]
  )
}

export function useUuid () {
  const params = useParams()

  return useMemo(() => {
    try {
      const uuid = _.get(params, 'uuid')
      if (_.isNil(uuid) || _.toString(uuid).replace(/[^-]/g, '').length !== 1) {
        return {}
      }

      const [address, _networkId] = _.toString(uuid).split('-') || []
      const networkId = parseInt(_networkId, 10)

      if (!ethers.utils.isAddress(address)) return {}
      if (!networks._supported_factory.includes(networkId)) return {}

      return {
        uuid: uuid.toLowerCase(),
        address: address.toLowerCase(),
        networkId
      }
    } catch (e) {
      console.error(e)
    }
    return {}
  }, [params])
}

export function useNews () {
  return news[0]
}
