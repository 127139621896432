import { macros } from '@pods-finance/globals'
import { useAtomicReducer } from '@pods-finance/hooks'

const initial = {
  factory: {
    value: null,
    warning: null,
    isPrimary: true
  },
  classification: {
    value: { title: 'Put Option', value: macros.OPTION_TYPE.put },
    source: [
      { title: 'Put Option', value: macros.OPTION_TYPE.put },
      { title: 'Call Option', value: macros.OPTION_TYPE.call }
    ],
    warning: null,
    isPrimary: true
  },
  localization: {
    value: { title: 'European Option', value: macros.EXERCISE_TYPE.european },
    source: [
      { title: 'European', value: macros.EXERCISE_TYPE.european },
      { title: 'American', value: macros.EXERCISE_TYPE.american }
    ],
    warning: null,
    isPrimary: true
  },
  name: {
    value: null,
    warning: null,
    suggestion: null,
    isPrimary: true
  },
  symbol: {
    value: null,
    warning: null,
    suggestion: null,
    isPrimary: true
  },
  underlying: {
    value: null,
    token: 'UNKNOWN',
    warning: null,
    isPrimary: true
  },
  strike: {
    value: null,
    token: 'UNKNOWN',
    warning: null,
    isPrimary: true
  },
  price: {
    value: null,
    warning: null,
    isPrimary: true
  },
  expiration: {
    value: null,
    warning: null,
    milliseconds: null,
    isPrimary: true
  },
  window: {
    value: 86400,
    warning: null,
    isPrimary: true
  },
  aave: {
    value: { title: '(No) Normal ERC20', value: false },
    source: [
      { title: '(No) Normal ERC20', value: false },
      { title: '(Yes) Aave aToken', value: true }
    ],
    warning: null,
    isPrimary: true
  }
}

export default {
  useReducer: () => useAtomicReducer(initial)
}
