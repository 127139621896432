import React from 'react'
import styled from 'styled-components'
import { useUserCrafted } from '../../../../hooks'
import Pod from './Pod'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
`

export default function Crafts () {
  const { crafted } = useUserCrafted()

  return (
    <Wrapper>
      {crafted.map(option => (
        <Pod key={option.uuid} option={option} />
      ))}
    </Wrapper>
  )
}
