import React from 'react'
import styled from 'styled-components'
import { Structure, Toolbar } from '../../components/common'

const Announcement = styled.p`
  ${props => props.theme.styles.tableParagraph};
  text-align: center;
  padding: 200px 0;
`

function Unsupported () {
  return (
    <Structure>
      <Toolbar />
      <Announcement>This network is not supported.</Announcement>
    </Structure>
  )
}

export default Unsupported
