import { ethers } from 'ethers'

export function onChangeAddress ({ value: _value, elements, dispatch }) {
  const value = String(_value).trim()
  let warning = null

  if (value.length && !ethers.utils.isAddress(value)) {
    warning = 'Incompatible evm contract address.'
  }

  dispatch([
    elements.address,
    {
      value,
      warning
    }
  ])
}

export function onChangeNetwork ({ value, elements, dispatch }) {
  dispatch([
    elements.network,
    {
      value,
      warning: null
    }
  ])

  dispatch([
    elements.address,
    {
      warning: null
    }
  ])
}

export function onTransact ({
  machine,
  state,
  elements,
  dispatch,
  watched,
  addWatched
}) {
  machine.send(machine.events.save, {
    payload: {
      state,
      elements,
      dispatch,
      watched,
      addWatched
    }
  })
}
