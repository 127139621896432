import React from 'react'
import {
  HashRouter,
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import Theme from '@pods-finance/themes'
import { HelmetProvider } from 'react-helmet-async'
import { ToastProvider } from 'react-toast-notifications'
import { ApolloProvider } from 'react-apollo'
import { Toast } from '@pods-finance/components'
import { ENVIRONMENT } from '@pods-finance/globals'
import {
  PriceProvider,
  ModalProvider,
  TableProvider
} from '@pods-finance/contexts'

import { pages, features } from '../constants'
import { client } from '../apollo/client'

import UIProvider from '../contexts/UI'
import Web3Provider from '../contexts/Web3'
import DataStaticProvider from '../contexts/DataStatic'
import DataDynamicProvider from '../contexts/DataDynamic'

import Craft from './Craft'
import Dashboard from './Dashboard'
import Option from './Option'
import Profile from './Profile'
import Watcher from './Watcher'
import Unsupported from './Unsupported'

function Router ({ children }) {
  if (features.ipfs) return <HashRouter>{children}</HashRouter>
  return <BrowserRouter>{children}</BrowserRouter>
}

function Wrapper ({ children }) {
  return (
    <ApolloProvider client={client}>
      <UIProvider>
        <Web3Provider>
          <DataStaticProvider>
            <DataDynamicProvider>
              <ModalProvider>
                <PriceProvider>
                  <TableProvider>{children}</TableProvider>
                </PriceProvider>
              </ModalProvider>
            </DataDynamicProvider>
          </DataStaticProvider>
        </Web3Provider>
      </UIProvider>
    </ApolloProvider>
  )
}

function Manager ({ children }) {
  return (
    <Theme.Provider isDark>
      <Theme.PlatformGlobalStyle isDark />
      <HelmetProvider>
        <ToastProvider
          components={{ Toast: Toast.Element, ToastContainer: Toast.Container }}
        >
          {children}
        </ToastProvider>
      </HelmetProvider>
    </Theme.Provider>
  )
}

function Routes () {
  return (
    <Switch>
      <Route path={pages.dashboard.route} component={Dashboard} exact />
      <Route path={pages.craft.route} component={Craft} exact />
      <Route path={pages.profile.route} component={Profile} exact />
      <Route path={pages.option.route} component={Option} exact />
      <Route path={pages.unsupported.route} component={Unsupported} exact />
      <Redirect to={pages.dashboard.route} />
    </Switch>
  )
}

/**
 * The Watcher component will activate special page-level events
 */

function App () {
  console.log(
    `%cWelcome to the Pods factory! #${String(ENVIRONMENT.current).substr(
      0,
      1
    )}`,
    'color: #C41857'
  )
  return (
    <Router>
      <Wrapper>
        <Manager>
          <Watcher />
          <Routes />
        </Manager>
      </Wrapper>
    </Router>
  )
}

export default App
