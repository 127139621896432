import { macros, networks } from '@pods-finance/globals'
import { useCallback, useEffect, useMemo, useState } from 'react'

import SDK from '@pods-finance/sdk'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import _ from 'lodash'
import { ethers } from 'ethers'
import { resolveAddressToENS } from '@pods-finance/utils'
import { useLocalStorage } from '@pods-finance/hooks'

const initial = {
  provider: undefined,
  signer: undefined,
  address: undefined,
  networkId: undefined,
  isLoading: true,
  isExpected: false,
  ens: {
    value: undefined,
    isResolved: false,
    isLoading: false
  }
}

const options = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      qrcode: true,
      infuraId: process.env.REACT_APP_INFURA_ID,
      bridge: 'https://bridge.walletconnect.org',
      rpc: {
        [networks.mainnet]: networks._data[networks.mainnet].endpoint(
          process.env.REACT_APP_INFURA_ID
        ),
        [networks.kovan]: networks._data[networks.kovan].endpoint(
          process.env.REACT_APP_INFURA_ID
        ),
        [networks.matic]: networks._data[networks.matic].endpoint(
          process.env.REACT_APP_INFURA_ID
        ),
        [networks.arbitrum]: networks._data[networks.arbitrum].endpoint(
          process.env.REACT_APP_INFURA_ID
        ),
        [networks.optimism]: networks._data[networks.optimism].endpoint(
          process.env.REACT_APP_INFURA_ID
        )
      },
      supportedChainIds: networks._supported_factory
    }
  }
}

const _modal = new Web3Modal({
  cacheProvider: true,
  providerOptions: options
})

/**
 *
 * Configuration Helper Functions
 *
 */

function cleanup () {
  if (
    !_.isNil(window) &&
    _.has(window, 'ethereum') &&
    _.has(window, 'ethereum.autoRefreshOnNetworkChange')
  ) {
    window.ethereum.autoRefreshOnNetworkChange = false
  }
}

export async function leave ({ modal, reset = _.noop }) {
  try {
    await modal.clearCachedProvider()
  } catch (e) {
    console.error('Web3 Modal', e)
  } finally {
    reset()
    window.location.reload()
  }
}

async function setup ({ modal, update, reset }) {
  try {
    const web3Provider = await modal.connect()

    const reactOnChange = async (web3Provider, first = false) => {
      cleanup()
      const provider = new ethers.providers.Web3Provider(web3Provider, 'any')
      const list = await provider.listAccounts()

      if (!list.length) {
        console.error(
          'Web3 provider is missing an account list. Signer will not be initialized.'
        )
        return
      }

      const signer = provider.getSigner()
      const networkId = ((await provider.getNetwork()) || {}).chainId
      const address = await signer
        .getAddress()
        .then(a => a)
        .catch(e => {
          throw new Error(e)
        })

      update({
        ...(first ? initial : {}),
        modal,
        signer,
        provider,
        address,
        networkId,
        isLoading: false,
        isExpected: true,
        ens: {
          value: null,
          isLoading: false,
          isResolved: false
        }
      })
    }

    if (web3Provider) {
      web3Provider.on('accountsChanged', () => reactOnChange(web3Provider))
      web3Provider.on('chainChanged', () => reactOnChange(web3Provider))
      await reactOnChange(web3Provider, true)
    }
  } catch (e) {
    console.error('Web3: ', e)
    if (e !== 'Modal closed by user') leave({ modal, reset })
  }
}

export function useENS ({ state, update }) {
  const provider = useWeb3Emergency(networks.mainnet)

  useEffect(() => {
    ;(async () => {
      if (
        !_.isNil(state.provider) &&
        !_.isNilOrEmptyString(state.address) &&
        state.ens.isLoading === false &&
        state.ens.isResolved === false
      ) {
        update({
          ens: {
            value: undefined,
            isLoading: true,
            isResolved: false
          }
        })

        const ens = await resolveAddressToENS(state.address, provider)

        update({
          ens: {
            value: ens,
            isLoading: false,
            isResolved: true
          }
        })
      }
    })()
  }, [state, update, provider])
}

export function useSetup () {
  /**
   * Instantiate the Web3 Modal
   */

  const modal = _modal

  /**
   * Prepare the state
   */

  const [isExpected] = useLocalStorage(macros.WEB3_MODAL_IDENTIFIER, null)

  const initialized = useMemo(
    () => ({
      ...initial,
      isExpected: !_.isNilOrEmptyString(isExpected)
    }),
    [isExpected]
  )

  const [state, setState] = useState(initialized)

  /**
   * Prepare the helper methods
   */

  const reset = useCallback(() => {
    setState({ ...initialized, isExpected: false })
  }, [setState, initialized])

  const update = useCallback(
    body => {
      setState(prev => ({
        ...prev,
        ...body
      }))
    },
    [setState]
  )

  const connect = useCallback(() => {
    setup({ modal, update, reset })
  }, [modal, update, reset])

  const disconnect = useCallback(() => {
    leave({ modal: state.modal, reset })
  }, [state.modal, reset])

  /**
   * Trigger effects
   */

  useENS({ state, update })

  /**
   * The master effect should not depend on modal. It is enough to create it and use that initial instance.
   */
  useEffect(() => {
    if (modal.cachedProvider) {
      setup({ modal, update, reset })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, reset])

  return {
    state,
    connect,
    disconnect
  }
}

export function getInfuraProvider (networkId) {
  return SDK.clients.provider.getBaseProvider(networkId, {
    infura: process.env.REACT_APP_INFURA_ID || ''
  })
}

export function useWeb3Emergency (networkId) {
  const [instance, setInstance] = useState()
  useEffect(() => {
    if (networkId && networks._supported_factory.includes(networkId)) {
      const infura = getInfuraProvider(networkId)
      setInstance(infura)
    }
  }, [networkId])
  return instance
}
